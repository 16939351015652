import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Layout from '../Layout/Layout';

const AuthenticationRoutes = ({
  component: Component,
  path,
  location,
  rollbar,
  auth,
}) => {
  const { logged, user } = auth;

  if (logged) {
    if (user) {
      const { logon, email, utilisateur } = user;
      rollbar.configure({
        payload: {
          person: {
            id: utilisateur,
            username: logon,
            email,
          },
        },
      });
    }

    let redirect = _.get(location, 'state.referer', '/');
    if (redirect.pathname === '/logout') {
      redirect = '/';
    }

    return <Redirect to={redirect} />;
  }

  return (
    <Layout
      exact
      path={path}
      component={Component}
    />
  );
};

AuthenticationRoutes.propTypes = {
  rollbar: PropTypes.shape({
    configure: PropTypes.func.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    logged: PropTypes.bool.isRequired,
    user: PropTypes.shape(),
  }).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      referer: PropTypes.shape().isRequired,
    }),
  }),
  path: PropTypes.string.isRequired,
};

AuthenticationRoutes.defaultProps = {
  location: { state: { referer: '/' } },
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(AuthenticationRoutes);
