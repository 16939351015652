import * as types from './actionTypes';

// login actions
export const restoreStateFromStorage = () => ({
  type: types.RESTORE_STATE_FROM_STORAGE,
});

export const restoreStateFromStorageError = () => ({
  type: types.RESTORE_STATE_FROM_STORAGE_ERROR,
});

export const login = ({ logon, password }) => (
  {
    type: types.LOGIN,
    logon,
    password,
  }
);

export const loginError = error => ({
  type: types.LOGIN_ERROR,
  error,
});

export const loginSuccess = ({ user, token }) => (
  {
    type: types.LOGIN_SUCCESS,
    user,
    token,
  }
);

// logout actions
export const logout = () => (
  {
    type: types.LOGOUT,
  }
);

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

export const logoutError = error => ({
  type: types.LOGOUT_ERROR,
  error,
});

export const fetchError = response => ({
  type: types.FETCH_ERROR,
  response,
});
