import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { TableCell } from '@material-ui/core';
import _ from 'lodash';
import { cleanupUsers, fetchUsers, openCloseUsers } from '../services/users/actions';
import { OPENED_USER_REQUEST } from '../config/constants';
import Users from '../components/Users/Users';

// Correctif pour mui-datatable ^3.8.0
const oldRender = TableCell.render
TableCell.render = function (...args) {
    const [props, ...otherArgs] = args
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
    } else {
        return oldRender.apply(this, args)
    }
}

const UsersContainer = ({ enqueueSnackbar }) => {
  const loading = useSelector((state) => state.users.loading);
  const users = useSelector((state) => state.users.list);
  const [statusFilter, setStatusFilter] = useState(OPENED_USER_REQUEST);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ etat: statusFilter }));
  }, [statusFilter]);

  useEffect(() => (
    () => dispatch(cleanupUsers())
  ), [dispatch]);

  const handleToggleStatusButton = (value) => {
    setStatusFilter(value);
  };

  const handleClickOpenClose = (userId, open) => {
    setSelectedRows([]);
    dispatch(openCloseUsers([userId], open, statusFilter));
  };

  const handleClickOpenCloseSelected = (rowsSelected, data, open) => {
    const ids = [];
    for (let i = 0; i < rowsSelected.data.length; i++) {
      if (data[rowsSelected.data[i].dataIndex]) {
        ids.push(data[rowsSelected.data[i].dataIndex][0]);
      }
    }
    setSelectedRows([]);
    dispatch(openCloseUsers(ids, open, statusFilter));
  };

  const handleClickEdit = (user) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: user,
        actionType: 'EDIT_USER',
      },
    });
  }

  const handleClickEditRights = (user) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: user,
        actionType: 'EDIT_USER_RIGHTS',
      },
    });
  }

  return (
    <Users
      users={users}
      loading={loading}
      handleToggleStatusButton={handleToggleStatusButton}
      statusFilter={statusFilter}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      handleClickEdit={handleClickEdit}
      handleClickEditRights={handleClickEditRights}
      handleClickOpenClose={handleClickOpenClose}
      handleClickOpenCloseSelected={handleClickOpenCloseSelected}
    />
  );
}
const enhancer = _.flowRight(
  withSnackbar,
);

export default enhancer(UsersContainer);
