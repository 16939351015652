import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  List as ListIcon,
  BubbleChart,
  DeleteSweep,
  InsertDriveFile,
  MenuBook,
  Publish,
  Build,
  SyncAlt,
  Edit,
  Add,
  Delete,
} from '@material-ui/icons';
import Sidenav from '../Sidenav/Sidenav';
import cx from 'classnames';
import s from './AdminMenu.module.sass';
import { ontologyPropType } from '../../core/proptypes';
import TooltipButton from '../TooltipButton/TooltipButton';

const AdminMenu = ({
  activeOntology,
  canDeleteOntology,
  userIsSuperAdmin,
  handleSetDialogEditOntology,
  handleSetDeletingOntology,
  handleSetDialogNewOntology,
  handleSetDialogExportOntology,
  handleSetDialogImport,
  handleSetDialogPurgeSource,
}) => (
  <Box display="flex" className={s.root}>
    {activeOntology.show_admin && (
      <Sidenav
        button={(
          <Fragment>
            <Build className={s.manageIcon} />
            Administrer
          </Fragment>
        )}
        className={cx(s.sidenavRoot, s.manageButton)}
        anchor="left"
      >
        {userIsSuperAdmin && (
          <Fragment>
            <div className={s.menuSectionTitle}>
              Modifier
            </div>
            <List>
              <ListItem button onClick={handleSetDialogEditOntology}>
                <ListItemIcon className={s.listIconSmall}>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary="Modifier l'ontologie" />
              </ListItem>
              <Tooltip
                title={canDeleteOntology ? (
                    ''
                  ) : (
                    <Fragment>
                      <Typography
                        color="inherit"
                        variant="h6"
                      >
                        L'ontologie ne peut-être supprimée que si :
                      </Typography>
                      <div>
                        &emsp;elle ne contient aucun mot-clé
                      </div>
                      <div>
                        &emsp;elle ne contient aucun noeud (sauf la corbeille)
                      </div>
                    </Fragment>
                  )
                }
              >
                {/* le span autour du ListItem est un "hack" pour montrer le tooltip même si le bouton est disabled */}
                <span>
                  <ListItem button disabled={!canDeleteOntology} onClick={handleSetDeletingOntology}>
                    <ListItemIcon className={s.listIconSmall}>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText primary="Supprimer l'ontologie" />
                  </ListItem>
                </span>
              </Tooltip>
            </List>
          </Fragment>
        )}
        <Divider />
        <div className={s.menuSectionTitle}>
          Import
        </div>
        <List>
          {activeOntology.show_import_wikidata
            && (
              <ListItem button onClick={() => handleSetDialogImport('wikidata')}>
                <ListItemIcon className={s.listIconSmall}>
                  <MenuBook />
                </ListItemIcon>
                <ListItemText primary="Wikidata" />
              </ListItem>
            )
          }
          {activeOntology.show_import_elastic
            && (
              <ListItem button onClick={() => handleSetDialogImport('elastic')}>
                <ListItemIcon className={s.listIconSmall}>
                  <BubbleChart />
                </ListItemIcon>
                <ListItemText primary="Elastic" />
              </ListItem>
            )
          }
          {activeOntology.show_import_fichier
            && (
              <ListItem button onClick={() => handleSetDialogImport('file')}>
                <ListItemIcon className={s.listIconSmall}>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary="Fichier" />
              </ListItem>
            )
          }
          {activeOntology.show_import_suggestion
            && (
              <ListItem button onClick={() => handleSetDialogImport('suggestionsFile')}>
                <ListItemIcon className={s.listIconSmall}>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary="Suggestions" />
              </ListItem>
            )
          }
          {activeOntology.show_import_relation
            && (
              <ListItem button onClick={() => handleSetDialogImport('relations')}>
                <ListItemIcon className={s.listIconSmall}>
                  <SyncAlt />
                </ListItemIcon>
                <ListItemText primary="Relations" />
              </ListItem>
            )
          }
          {activeOntology.show_import_unique
            && (
              <ListItem button onClick={() => handleSetDialogImport('uniqueKeywords')}>
                <ListItemIcon className={s.listIconSmall}>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Mots uniques" />
              </ListItem>
            )
          }
        </List>
        {activeOntology.show_export
          && (
            <Fragment>
              <Divider />
              <div className={s.menuSectionTitle}>
                Export
              </div>
              <List>
                <ListItem button onClick={handleSetDialogExportOntology}>
                  <ListItemIcon className={s.listIconSmall}>
                    <Publish />
                  </ListItemIcon>
                  <ListItemText primary="Export" />
                </ListItem>
              </List>
            </Fragment>
          )
        }
        {activeOntology.show_purge
          && (
            <Fragment>
              <Divider />
              <div className={s.menuSectionTitle}>
                Purge
              </div>
              <List>
                <ListItem button onClick={handleSetDialogPurgeSource}>
                  <ListItemIcon className={s.listIconSmall}>
                    <DeleteSweep />
                  </ListItemIcon>
                  <ListItemText primary="Purger une source" />
                </ListItem>
              </List>
            </Fragment>
          )
        }
      </Sidenav>
    )}
    {userIsSuperAdmin && (
      <TooltipButton
        title="Nouvelle Ontologie"
        className={s.whiteButton}
        onClick={handleSetDialogNewOntology}
      >
        <Add className={s.whiteButtonIcon} />
        Créer
      </TooltipButton>
    )}
  </Box>
);

AdminMenu.propTypes = {
  userIsSuperAdmin: PropTypes.bool.isRequired,
  activeOntology: ontologyPropType.isRequired,
  handleSetDialogEditOntology: PropTypes.func.isRequired,
  handleSetDialogNewOntology: PropTypes.func.isRequired,
  handleSetDialogExportOntology: PropTypes.func.isRequired,
  handleSetDialogImport: PropTypes.func.isRequired,
  handleSetDialogPurgeSource: PropTypes.func.isRequired,
};

export default AdminMenu;
