import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import { SlideUp } from './Transitions/Transitions';
import DraggablePaper from './DraggablePaper/DraggablePaper';
import { ontologyPropType, rightPropType, utilisateurPropType } from '../core/proptypes';
import DialogConfirmButtonContainer from '../containers/DialogConfirmButtonContainer';
import { CheckBoxOutlineBlank, Lock } from '@material-ui/icons';
import { defaultRight } from '../config/constants';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiTableCell-sizeSmall': {
      padding: '4px 10px 4px 8px',
    },
    '& td.MuiTableCell-sizeSmall': {
      wordBreak: 'break-word',
    },
  },
  title: {
    alignItems: 'center',
    backgroundColor: 'lighten($primary-color, 50%)',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
  },
}));


const UserRights = ({
  user,
  handleClose,
  handleSubmit,
  ontologies,
  userRights,
  loadingOntologies,
  loadingRights,
}) => {
  const [rights, setRights] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [sortTableOrder, setTableSortOrder] = useState({
    name: 'nature',
    direction: 'asc',
  });
  const classes = useStyles();

  const handleAddRight = (ontologyId) => {
    let originalRight = _.find(userRights, { nature: ontologyId });
    if (!originalRight) {
      originalRight = defaultRight;
    }
    setRights(
      [
        ...rights,
        {
          ...originalRight,
          utilisateur: user.utilisateur,
          nature: ontologyId,
        }
      ]
    );
  };

  const handleDeleteRight = (ontologyId) => {
    const newRights = [...rights];
    _.remove(newRights, { nature: ontologyId });
    setRights(newRights);
  };

  const handleUpdateRights = (ontologyId, field, value) => {
    var newRights = _.map(
      rights,
      (right) => ({
        ...right,
        [field]: right.nature === ontologyId ? value : right[field],
      }),
    );
    setRights(newRights);
  };

  const options = {
    sort: false,
    customBodyRender: (item, tableMeta) => {
      const ontologyId = tableMeta.rowData[0];
      const hasAccess = tableMeta.rowData[1];
      const fieldName = tableMeta.columnData.name;
      return (
        <FormControlLabel
          title={item.disabled ? 'Cette option n\'est pas activée sur l\'ontologie' : ''}
          control={(
            <Checkbox
              icon={item.disabled ? <Lock /> : <CheckBoxOutlineBlank />}
              checked={item.checked}
              disabled={item.disabled || !hasAccess}
              color="primary"
              onChange={(event) => handleUpdateRights(ontologyId, fieldName, event.target.checked)}
            />
          )}
        />
      );
    },
  };

  const columns = [
    {
      name: 'nature',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'access',
      label: "Accès",
      options: {
        customBodyRender: (hasRightForOntology, tableMeta) => {
          const ontologyId = tableMeta.rowData[0];
          return (
            <Switch
              checked={hasRightForOntology}
              onChange={
                (event) => {
                  if (event.target.checked) {
                    handleAddRight(ontologyId);
                  } else {
                    handleDeleteRight(ontologyId);
                  }
                }
              }
              color="primary"
            />
          );
        },
      },
    },
    {
      name: 'label',
      label: "Ontologie",
    },
    {
      name: 'show_admin',
      label: "Menu d'admin",
      options,
    },
    {
      name: 'show_create',
      label: "Création de mots-clés",
      options,
    },
    {
      name: 'show_update',
      label: "Modification de mots-clés",
      options,
    },
    {
      name: 'show_delete',
      label: "Suppression de mots-clés",
      options,
    },
    {
      name: 'show_export',
      label: "Export",
      options,
    },
    {
      name: 'show_import_wikidata',
      label: "Import Wikidata",
      options,
    },
    {
      name: 'show_import_elastic',
      label: "Import Elastic",
      options,
    },
    {
      name: 'show_import_fichier',
      label: "Import Fichier",
      options,
    },
    {
      name: 'show_import_suggestion',
      label: "Import Suggestions",
      options,
    },
    {
      name: 'show_import_relation',
      label: "Import Relations",
      options,
    },
    {
      name: 'show_import_unique',
      label: "Import Unique",
      options,
    },
    {
      name: 'show_purge',
      label: "Purge",
      options,
    },
  ];

  useEffect(() => {
    if (!loadingOntologies && !loadingRights && userRights !== null) {
      setRights(userRights);
    }
  }, [userRights]);

  useEffect(() => {;
    if (rights !== null) {
      setTableData(ontologies.map(
        (ontology) => {
          const userOntologyRights = _.find(rights, { nature: ontology.nature }, {});
          return (
            [
              ontology.nature,
              !_.isEmpty(userOntologyRights),
              ontology.label,
              {
                disabled: !ontology.show_admin,
                checked: userOntologyRights?.show_admin || false,
              },
              {
                disabled: !ontology.show_create,
                checked: userOntologyRights?.show_create || false,
              },
              {
                disabled: !ontology.show_update,
                checked: userOntologyRights?.show_update || false,
              },
              {
                disabled: !ontology.show_delete,
                checked: userOntologyRights?.show_delete || false,
              },
              {
                disabled: !ontology.show_export,
                checked: userOntologyRights?.show_export || false,
              },
              {
                disabled: !ontology.show_import_wikidata,
                checked: userOntologyRights?.show_import_wikidata || false,
              },
              {
                disabled: !ontology.show_import_elastic,
                checked: userOntologyRights?.show_import_elastic || false,
              },
              {
                disabled: !ontology.show_import_fichier,
                checked: userOntologyRights?.show_import_fichier || false,
              },
              {
                disabled: !ontology.show_import_suggestion,
                checked: userOntologyRights?.show_import_suggestion || false,
              },
              {
                disabled: !ontology.show_import_relation,
                checked: userOntologyRights?.show_import_relation || false,
              },
              {
                disabled: !ontology.show_import_unique,
                checked: userOntologyRights?.show_import_unique || false,
              },
              {
                disabled: !ontology.show_purge,
                checked: userOntologyRights?.show_purge || false,
              },
            ]
          );
        }
      ));
    }
  }, [rights]);

  const datatableOptions = {
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    elevation: 0,
    fixedHeader: true,
    textLabels: {
      body: {
        noMatch: 'Désolé, aucune ontologie ne correspond',
        toolTip: 'Trier',
      },
      pagination: {
        next: 'Page suivante',
        previous: 'Page précédente',
      },
    },
    searchPlaceholder: 'Filtrer la liste des ontologies',
    download: false,
    filter: false,
    print: false,
    searchAlwaysOpen: true,
    viewColumns: false,
    selectableRows: 'none',
    setTableProps: () => ({ size: 'small' }),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          const table = document.querySelector('#ontologies-datatable  .MuiTable-root');
          table.scrollIntoView(true);
          break;
        case 'sort':
        case 'search':
        case 'filterChange':
        case 'resetFilters':
          setTableSortOrder(tableState.sortOrder);
          break;
        default:
          break;
      }
    },
    sortOrder: sortTableOrder,
  };

  return (
    <Dialog
      className={classes.root}
      onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
      }}
      open
      maxWidth="md"
      fullWidth
      TransitionComponent={SlideUp}
      PaperComponent={DraggablePaper}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: 'move' }}
        id="draggable-dialog-title"
        disableTypography
        className={classes.title}
      >
        <Typography variant="h6">
          {`Edition des droits de l'utilisateur : ${user.prenom} ${user.nom}`}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.dialogContent}
      >
        <Box id="ontologies-datatable" overflow="auto">
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={datatableOptions}
            dense
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <DialogConfirmButtonContainer
          type="submit"
          color="primary"
          onClick={() => handleSubmit(rights)}
        >
          Enregistrer
        </DialogConfirmButtonContainer>
      </DialogActions>
    </Dialog>
  );
}

UserRights.propTypes = {
  user: utilisateurPropType.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  ontologies: PropTypes.arrayOf(ontologyPropType).isRequired,
  userRights: PropTypes.arrayOf(
    PropTypes.shape(rightPropType),
  ),
  loadingOntologies: PropTypes.bool.isRequired,
  loadingRights: PropTypes.bool.isRequired,
};

UserRights.defaultProps = {
  userRights: null,
};

export default UserRights;
