import { combineReducers } from 'redux';
import queryString from 'query-string';
import { fieldsWhitelist } from '../config/constants';
import _ from 'lodash';
import {
  addKeywordsToList,
  addNode,
  deleteKeywordsFromList,
  deleteNode,
  getAllParents,
  getChildrenToCheck,
  modifyNbKeywords,
  moveStoreNode,
  patchKeywordsInList,
  patchStoreNode,
  setAdditionalData,
  toggleEditLabel,
  toggleOpen,
  toggleOpenAll,
  updatePaths,
} from '../utils/ontology-manager-utils';
import * as types from '../services/auth/actionTypes';

const initialOntologiesReducerState = {
  list: [],
  loading: true,
  active_ontology: null,
};
const ontologiesReducer = (state = initialOntologiesReducerState, action) => {
  switch (action.type) {
    case types.LOGOUT: {
      return initialOntologiesReducerState;
    }
    case 'FETCH_ONTOLOGIES': {
      return {
        ...state,
        list: [],
        loading: true,
      };
    }
    case 'FETCH_ONTOLOGIES_SUCCESS': {
      return {
        ...state,
        list: action.ontologies,
        loading: false,
      };
    }
    case 'FETCH_ONTOLOGIES_ERROR': {
      return {
        ...state,
        list: [],
        loading: false,
      };
    }
    case 'FETCH_ACTIVE_ONTOLOGY': {
      return {
        ...state,
        active_ontology: action.ontology,
      };
    }
    case 'REFRESH_ACTIVE_ONTOLOGY': {
      return {
        ...state,
        active_ontology: {
          ...action.ontology,
          mustRefresh: true,
        },
        list: state.list.map(
          ontology => (
            ontology.id === action.ontology.id ? action.ontology : ontology
          ),
        ),
      };
    }
    case 'REFRESH_ACTIVE_ONTOLOGY_DONE': {
      return {
        ...state,
        active_ontology: {
          ...state.active_ontology,
          mustRefresh: false,
        },
      };
    }
    case 'ONTOLOGY_CREATED': {
      return {
        ...state,
        list: [
          ...state.list,
          action.ontology,
        ],
      };
    }
    case 'ONTOLOGY_UPDATED': {
      return {
        ...state,
        active_ontology: {
          ...action.ontology,
        },
        list: state.list.map(
          ontology => (
            ontology.id === action.ontology.id ? action.ontology : ontology
          ),
        ),
      };
    }
    case 'ONTOLOGY_DELETED': {
      return {
        ...state,
        list: _.filter(state.list, (ontology) => ontology.id !== action.ontology.id),
      };
    }
    default:
      return state;
  }
};

const initialTreeReducerState = {
  content: {},
  loading: false,
  showAll: false,
  moveItemsLoading: false,
  moveItemsNodeId: null,
  parents: {},
};
const treeReducer = (state = initialTreeReducerState, action) => {
  switch (action.type) {
    case types.LOGOUT: {
      return initialTreeReducerState;
    }
    case 'RESET_TREE': {
      return {
        ...state,
        content: {},
      };
    }
    case 'BIN_EMPTIED': {
      return {
        ...state,
        content: {
          ...state.content,
          children: state.content.children.map(
            child => child.type === 'bin' ? (
              {
                ...child,
                nb_keywords: 0,
                nb_keywords_self: 0,
              }
            ) : child,
          ),
        },
      };
    }
    case 'FETCH_TREE': {
      return {
        ...state,
        content: {},
        loading: true,
      };
    }
    case 'FETCH_TREE_SUCCESS': {
      const parents = getAllParents(action.tree.children, '', []);
      let children = setAdditionalData(action.tree.children, [action.tree.id], '', 1);
      // On va chercher la corbeille
      let binIndex = null;
      for (let i = 0; i < children.length; i += 1) {
        if (children[i].type === 'bin') {
          binIndex = i;
          break;
        }
      }
      // Si elle existe, on la colle au début de l'arbre
      if (binIndex !== null) {
        const binArray = children.splice(binIndex, 1);
        children = binArray.concat(children);
      } else {
        // Sinon on la crée, sans ID
        children.unshift({
          id: null,
          type: 'bin',
          label: 'Corbeille',
          children: [],
          level: 1,
          nb_keywords: 0,
          nb_keywords_self: 0,
          path: [action.tree.id, null],
        });
      }
      // On considère que la corbeille est toujours affichée
      children[0].found = 1;
      return {
        ...state,
        content: {
          ..._.pickBy(action.tree, (value, key) => _.includes(fieldsWhitelist.nodes, key)),
          nbNodes: Object.keys(parents).length,
          label: 'Racine',
          path: [action.tree.id],
          hasChildren: (action.tree.children && action.tree.children > 0),
          level: 0,
          opened: true,
          isRoot: true,
          found: 1,
          children,
        },
        loading: false,
        parents,
      };
    }
    case 'FETCH_TREE_ERROR': {
      return {
        ...state,
        content: {},
        loading: false,
      };
    }
    case 'TOGGLE_SHOW_FULL_TREE': {
      return {
        ...state,
        showAll: !state.showAll,
      };
    }
    case 'UPDATE_PARENTS': {
      const parents = getAllParents(state.content.children, '', []);
      return {
        ...state,
        parents,
      };
    }
    case 'SET_BIN_ID': {
      const children = [...state.content.children];
      children[0].id = action.binId;
      children[0].path = [state.content.id, action.binId];
      return {
        ...state,
        content: {
          ...state.content,
          children,
        },
      };
    }
    case 'MOVE_ITEMS': {
      return {
        ...state,
        moveItemsLoading: true,
        moveItemsNodeId: action.nodeId,
      };
    }
    case 'MOVE_ITEMS_SUCCESS': {
      return {
        ...state,
        moveItemsLoading: false,
        moveItemsNodeId: null,
      };
    }
    case 'MOVE_ITEMS_ERROR': {
      return {
        ...state,
        moveItemsLoading: false,
        moveItemsNodeId: null,
      };
    }
    case 'NODE_ADDED': {
      // On récupère le tri courant de l'arbre
      const params = queryString.parse(window.location.search);
      const sort = params.st || 'alpha asc';
      const toRoot = (state.content.id === action.item.parent_id);
      return {
        ...state,
        content: {
          ...state.content,
          children: addNode(state.content.children, action.item, sort, toRoot),
        },
      };
    }
    case 'TOGGLE_ALL_NODES': {
      return {
        ...state,
        content: {
          ...state.content,
          opened: !state.content.opened,
          children: toggleOpenAll(state.content.children, !state.content.opened),
        },
      };
    }
    case 'TOGGLE_OPEN_NODE': {
      return {
        ...state,
        content: {
          ...state.content,
          opened: (action.item.id === state.content.id) ? (
            !state.content.opened
          ) : (
            state.content.opened
          ),
          children: (action.item.id !== state.content.id) ? (
            toggleOpen(state.content.children, action.item)
          ) : (
            state.content.children
          ),
        },
      };
    }
    case 'TOGGLE_EDIT_NODE_LABEL': {
      return {
        ...state,
        content: {
          ...state.content,
          children: toggleEditLabel(state.content.children, action.item),
        },
      };
    }
    case 'NODE_PATCHED': {
      let patchedState = {
        ...state,
        content: {
          ...state.content,
          pathFile: action.item.type === 'root' ? action.item.pathFile : state.content.pathFile,
          append: action.item.type === 'root' ? action.item.append : state.content.append,
          children: patchStoreNode(state.content.children, action.item),
        },
      };
      if (action.move) {
        const toRoot = (state.content.id === action.item.parent_id);
        patchedState = {
          ...patchedState,
          content: {
            ...patchedState.content,
            children: moveStoreNode(patchedState.content.children, action.item, action.sort, toRoot),
          },
        };
      }
      return patchedState;
    }
    case 'NODE_DELETED': {
      return {
        ...state,
        content: {
          ...state.content,
          children: deleteNode(state.content.children, action.item),
        },
      };
    }
    case 'UPDATE_PATHS': {
      return {
        ...state,
        content: {
          ...state.content,
          children: updatePaths(state.content.children, [state.content.id], ''),
        },
      };
    }
    case 'UPDATE_NB_KEYWORDS': {
      let patchedState = { ...state };
      if (action.payload.newNodeId && action.payload.nbAdded) {
        patchedState = {
          ...patchedState,
          content: {
            ...patchedState.content,
            nb_keywords: action.payload.nbNewInTree
              ? (patchedState.content.nb_keywords + action.payload.nbNewInTree)
              : patchedState.content.nb_keywords,
            children: modifyNbKeywords(
              patchedState.content,
              action.payload.newNodeId,
              action.payload.nbAdded,
              true,
              action.payload.isNodeMoving,
            ),
          },
        };
      }
      if (action.payload.oldNodeId && action.payload.nbDeleted) {
        patchedState = {
          ...patchedState,
          content: {
            ...patchedState.content,
            nb_keywords: action.payload.uncategorizing
              ? (patchedState.content.nb_keywords - action.payload.nbDeleted)
              : patchedState.content.nb_keywords,
            children: modifyNbKeywords(
              patchedState.content,
              action.payload.oldNodeId,
              action.payload.nbDeleted,
              false,
              action.payload.isNodeMoving,
            ),
          },
        };
      }
      return patchedState;
    }
    default:
      return state;
  }
};

const initialItemsReducerState = {
  active_node: null,
  uncats: null,
  suggestions: null,
};
const itemsReducer = (state = initialItemsReducerState, action) => {
  switch (action.type) {
    case types.LOGOUT: {
      return initialItemsReducerState;
    }
    case 'RESET_ITEMS': {
      return {
        ...state,
        active_node: {},
        uncats: {},
        suggestions: {},
      };
    }
    case 'FETCH_NODE': {
      return {
        ...state,
        active_node: {
          goToId: _.get(state, 'active_node.goToId', null),
          loading: true,
        },
      };
    }
    case 'FETCH_NODE_SUCCESS': {
      return {
        ...state,
        active_node: {
          ...action.active_node,
          children: action.active_node.children.map(
            child => ({
              ..._.pickBy(child, (value, key) => _.includes(fieldsWhitelist.keywords, key)),
              checked: false,
              editLabel: false,
            }),
          ),
          goToId: _.get(state, 'active_node.goToId', null),
          loading: false,
        },
      };
    }
    case 'FETCH_NODE_ERROR': {
      return {
        ...state,
        active_node: {},
      };
    }
    case 'FETCH_UNCATS': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          children: [],
          goToId: _.get(state, 'uncats.goToId', null),
          loading: true,
        },
      };
    }
    case 'FETCH_UNCATS_SUCCESS': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          children: action.uncats.map(
            uncat => ({
              ..._.pickBy(uncat, (value, key) => _.includes(fieldsWhitelist.keywords, key)),
              checked: false,
              editLabel: false,
            }),
          ),
          goToId: _.get(state, 'uncats.goToId', null),
          loading: false,
        },
      };
    }
    case 'FETCH_UNCATS_ERROR': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          children: [],
          loading: false,
        },
      };
    }
    case 'FETCH_SUGGESTION_ITEM': {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          item: action.item,
          itemsType: action.itemsType,
          children: [],
        },
      };
    }
    case 'SET_SUGGESTIONS_LOADING': {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: true,
        },
      };
    }
    case 'UNSET_SUGGESTIONS_LOADING': {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
        },
      };
    }
    case 'FETCH_SUGGESTIONS': {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
          children: action.results.map(
            child => ({
              ..._.pickBy(child, (value, key) => _.includes(fieldsWhitelist.keywords, key)),
              checked: false,
            }),
          ),
        },
      };
    }
    case 'FETCH_SUGGESTIONS_ERROR': {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          loading: false,
          children: [],
        },
      };
    }
    case 'UNSET_SUGGESTIONS': {
      return {
        ...state,
        suggestions: null,
      };
    }
    case 'TOGGLE_CHECK_ITEM': {
      // On prépare la liste des cases à cocher (ou à décocher), avec l'id de la
      // case cliquée en premier
      let childrenToCheck = [action.item.id];
      let forceCheck = false;
      if (action.checkWithShift) {
        // On a coché avec la touche shift appuyée, on va donc chercher
        // à cocher tous les éléments entre la case cochée et la précédente case
        // cochée (ou décochée)
        childrenToCheck = childrenToCheck.concat(getChildrenToCheck(state[action.stateLocation].children, action.item.id));
        // Si on a au moins une case en plus que la case cliquée c'est qu'on a bien
        // une liste d'éléments grace à shift, on va donc forcer le "check" de tous
        // les éléments
        if (childrenToCheck.length > 1) {
          forceCheck = true;
        }
      }
      return {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          children: state[action.stateLocation].children.map(
            child => (
              _.includes(childrenToCheck, child.id) ? {
                ...child,
                checked: forceCheck ? true : !child.checked,
                isLastChecked: child.id === action.item.id,
              } : {
                ...child,
                isLastChecked: false,
              }
            ),
          ),
          new_checked: true,
        },
      };
    }
    case 'TOGGLE_CHECK_ALL': {
      return {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          children: state[action.stateLocation].children.map(
            child => ({
              ...child,
              checked: action.check,
              isLastChecked: action.check,
            }),
          ),
        },
      };
    }
    case 'TOGGLE_EDIT_LABEL': {
      if (action.item.parent_id === 1000) {
        return {
          ...state,
          uncats: {
            ...state.uncats,
            children: state.uncats.children.map(
              uncat => (
                uncat.id === action.item.id ? {
                  ...uncat,
                  editLabel: !action.item.editLabel,
                } : uncat
              ),
            ),
          },
        };
      }
      return {
        ...state,
        active_node: {
          ...state.active_node,
          children: state.active_node.children.map(
            child => (
              child.id === action.item.id ? {
                ...child,
                editLabel: !action.item.editLabel,
              } : child
            ),
          ),
        },
      };
    }
    case 'SET_MARKER': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          markerId: action.itemId,
        },
      };
    }
    case 'REMOVE_MARKER': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          markerId: null,
        },
      };
    }
    case 'SET_GO_TO_MARKER': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          goToMarker: true,
        },
      };
    }
    case 'REMOVE_GO_TO_MARKER': {
      return {
        ...state,
        uncats: {
          ...state.uncats,
          goToMarker: false,
        },
      };
    }
    case 'SET_GO_TO_ID': {
      return {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          goToId: action.itemId,
        },
      };
    }
    case 'REMOVE_GO_TO_ID': {
      return {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          goToId: null,
        },
      };
    }
    case 'ITEMS_PATCHED': {
      let patchedState = {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          children: patchKeywordsInList(state[action.stateLocation].children, action.items),
        },
      };
      if (action.stateDestination) {
        let isGrouped;
        if (action.stateDestination === 'active_node') {
          const params = queryString.parse(window.location.search);
          isGrouped = (params.groupLeafs === 'true');
        }
        patchedState = {
          ...patchedState,
          [action.stateLocation]: {
            ...patchedState[action.stateLocation],
            children: deleteKeywordsFromList(patchedState[action.stateLocation].children, action.items),
          },
          [action.stateDestination]: {
            ...patchedState[action.stateDestination],
            children: addKeywordsToList(patchedState[action.stateDestination].children, action.items, action.sort, isGrouped),
          },
        };
      }
      return patchedState;
    }
    case 'ITEMS_CREATED': {
      let isGrouped;
      if (action.stateLocation === 'active_node') {
        const params = queryString.parse(window.location.search);
        isGrouped = (params.groupLeafs === 'true');
      }
      return {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          children: addKeywordsToList(state[action.stateLocation].children, action.items, action.sort, isGrouped),
        },
      };
    }
    case 'ITEMS_DELETED': {
      return {
        ...state,
        [action.stateLocation]: {
          ...state[action.stateLocation],
          children: deleteKeywordsFromList(state[action.stateLocation].children, action.items),
        },
      };
    }
    case 'MOVE_ITEMS_TO_NODE': {
      let alteredState = {};
      const newNodeId = action.payload.node.id;
      // On s'occupe d'abord des uncats
      if (newNodeId === 1000) {
        // Si on déplace tous les éléments dans les uncats
        // On récupère le tri courant des uncats
        const params = queryString.parse(window.location.search);
        const sort = params.su || 'count desc';
        // On ajoute les items dans les uncats du state
        alteredState = {
          ...state,
          uncats: {
            ...state.uncats,
            children: addKeywordsToList(state.uncats.children, action.payload.items, sort),
          },
        };
      } else {
        //  Sinon on vire tous les items des uncats
        alteredState = {
          ...state,
          uncats: {
            ...state.uncats,
            children: deleteKeywordsFromList(state.uncats.children, action.payload.items),
          },
        };
      }
      // Si il y a également un noeud ouvert, on s'en charge
      if (state.active_node) {
        // Si on déplace tous les éléments dans le noeud courant, on ajoute
        // les éléments dans la liste de celui-ci (colonne centrale), on s'assure
        // juste que le noeud destination ne soit pas la corbeille avec également
        // la racine qui est le noeud courant, si c'est le cas, on ne tente pas
        // d'ajouter les éléments dans la liste du noeud courant, on ne fait que les
        // supprimer de la liste (ça évite de voir des supprimés dans la liste)
        if (_.includes(action.payload.node.path, state.active_node.id) && !(action.payload.node.type === 'bin' && state.active_node.type === 'root')) {
          // On récupère le tri courant des keywords
          const params = queryString.parse(window.location.search);
          const sort = params.sn || 'count desc';

          // On a aussi besoin de savoir si le noeud actif est en mode "groupé"
          // ou non, on récupère donc l'info dans l'URL
          const isGrouped = (params.groupLeafs === 'true');

          // On ajoute les éléments dans le noeud courant
          alteredState = {
            ...alteredState,
            active_node: {
              ...alteredState.active_node,
              children: addKeywordsToList(
                alteredState.active_node.children,
                action.payload.items,
                sort,
                isGrouped,
              ),
            },
          };
        } else {
          // Si on déplace tous les éléments hors du noeud courant
          // On vire les éléments du noeud courant
          alteredState = {
            ...alteredState,
            active_node: {
              ...alteredState.active_node,
              children: deleteKeywordsFromList(
                alteredState.active_node.children,
                action.payload.items,
              ),
            },
          };
        }
      }
      // Si jamais on a des suggestions qui viennent d'être catégorisées, on
      // les vire de la liste courante des suggestions
      if (!_.isEmpty(action.payload.suggestionsToMove)) {
        alteredState = {
          ...alteredState,
          suggestions: {
            ...alteredState.suggestions,
            children: deleteKeywordsFromList(
              alteredState.suggestions.children,
              action.payload.suggestionsToMove,
            ),
          },
        };
      }
      return alteredState;
    }
    default:
      return state;
  }
};

const initialDialogReducerState = {
  item: null,
  actionType: null,
  loading: false,
};
const dialogReducer = (state = initialDialogReducerState, action) => {
  switch (action.type) {
    case types.LOGOUT: {
      return initialDialogReducerState;
    }
    case 'SET_DIALOG_ITEM': {
      return {
        ...state,
        ...action.dialog,
      };
    }
    case 'UNSET_IMPORT_FILE_PATH': {
      return {
        ...state,
        item: {
          ...state.item,
          filePath: null,
        },
      };
    }
    case 'SET_IMPORT_FILE_PATH': {
      return {
        ...state,
        item: {
          ...state.item,
          filePath: action.path,
        },
      };
    }
    case 'UNSET_DIALOG_ITEM': {
      return {
        ...state,
        item: null,
        actionType: null,
      };
    }
    case 'SET_DIALOG_LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'UNSET_DIALOG_LOADING': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'SET_DIALOG_LOADING_FILE': {
      return {
        ...state,
        loadingFile: true,
      };
    }
    case 'UNSET_DIALOG_LOADING_FILE': {
      return {
        ...state,
        loadingFile: false,
      };
    }
    case 'UNSET_DIALOG_FILE_UPLOADED': {
      return {
        ...state,
        fileUploaded: false,
      };
    }
    case 'SET_DIALOG_FILE_UPLOADED': {
      return {
        ...state,
        fileUploaded: true,
      };
    }
    case 'SET_PREVIEW_CHILDREN': {
      return {
        ...state,
        item: {
          ...state.item,
          children: action.children,
        },
      };
    }
    case 'UNSET_PREVIEW_CHILDREN': {
      return {
        ...state,
        item: {
          ...state.item,
          children: [],
        },
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  ontologies: ontologiesReducer,
  tree: treeReducer,
  items: itemsReducer,
  dialog: dialogReducer,
});
