import { types } from './actions';

const initialUsersReducerState = {
  list: [],
  loading: true,
  rights: null,
  rightsLoading: false,
  saveRightsLoading: false,
};
const usersReducer = (
  state=initialUsersReducerState,
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_USERS: {
      return initialUsersReducerState;
    }
    case types.FETCH_USERS: {
      return {
        ...state,
        list: [],
        loading: true,
      };
    }
    case types.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        list: action.users,
        loading: false,
      };
    }
    case types.FETCH_USERS_ERROR: {
      return {
        ...state,
        list: [],
        loading: false,
      };
    }
    case types.CLEANUP_USER_RIGHTS: {
      return {
        ...state,
        rights: null,
      };
    }
    case types.FETCH_USER_RIGHTS: {
      return {
        ...state,
        rights: null,
        rightsLoading: true,
      };
    }
    case types.FETCH_USER_RIGHTS_SUCCESS: {
      return {
        ...state,
        rights: action.rights,
        rightsLoading: false,
      };
    }
    case types.FETCH_USER_RIGHTS_ERROR: {
      return {
        ...state,
        rights: null,
        rightsLoading: false,
      };
    }
    default:
      return state;
  };
};

export default usersReducer;
