import { createBrowserHistory } from 'history';
import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleWare from 'redux-saga';

import ontologyManager from './ontology-manager-store';
import auth from '../services/auth/reducer';
import users from '../services/users/reducer';
import sagas from '../services/sagas';

const sagaMiddleware = createSagaMiddleWare();

export const history = createBrowserHistory();

const rootReducer = _history => combineReducers({
  router: connectRouter(_history),
  ontologyManager,
  auth,
  users,
});

export default function configureStore() {
  const store = createStore(
    rootReducer(history),
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(sagas);
  return store;
}
