import _ from 'lodash';

export const getAllParents = (children, parentName, parentPath) => {
  const allParents = {};

  for (let i = 0; i < children.length; i += 1) {
    if (children[i].type !== 'bin') {
      const newParentPath = [...parentPath];
      newParentPath.push(children[i].id);
      allParents[children[i].id] = {
        id: children[i].id,
        label: parentName + children[i].label,
        path: newParentPath,
        type: children[i].type,
      };
      const newParentName = `${parentName + children[i].label} / `;
      _.merge(allParents, getAllParents(children[i].children, newParentName, newParentPath));
    }
  }
  return allParents;
};

export const getClosestParentOfType = (parents, parentId, type) => {
  if (_.isEmpty(parents)) {
    return null;
  }
  const currentParent = parents[parentId];
  // Si on ne trouve même pas de parent via l'ID fourni, on ne fait rien
  // et on renvoie null
  if (!_.isEmpty(currentParent)) {
    if (currentParent.type === type) {
      return currentParent;
    }
    for (let i = currentParent.path.length - 2; i >= 0; i -= 1) {
      const testParent = parents[currentParent.path[i]];
      if (!_.isEmpty(testParent) && testParent.type === type) {
        return testParent;
      }
    }
  }
  return null;
};

export const findNodeById = (treeNode, nodeId) => {
  let node = null;

  if (treeNode.id === nodeId) {
    node = treeNode;
  }
  if (!node) {
    if (treeNode.children) {
      for (let i = 0; i < treeNode.children.length; i += 1) {
        const test = findNodeById(treeNode.children[i], nodeId);
        if (test !== null) {
          node = test;
          break;
        }
      }
    }
  }
  return node;
};

export const setAdditionalData = (children, path, labeledPath, level) => {
  const alteredChildren = children;
  if (alteredChildren.length > 0) {
    for (let i = 0; i < alteredChildren.length; i += 1) {
      alteredChildren[i].opened = true;
      alteredChildren[i].level = level;
      alteredChildren[i].path = path.concat(alteredChildren[i].id);
      const slash = labeledPath === '' ? '' : ' / ';
      alteredChildren[i].labeledPath = `${labeledPath}${slash}${alteredChildren[i].label}`;
      const newLevel = level + 1;
      alteredChildren[i].children = setAdditionalData(
        alteredChildren[i].children,
        path.concat(alteredChildren[i].id),
        `${labeledPath}${slash}${alteredChildren[i].label}`,
        newLevel,
      );
    }
  }
  return alteredChildren;
};

export const makeTreeFlat = (children) => {
  let flatTree = [];
  const alteredChildren = children;
  for (let i = 0; i < alteredChildren.length; i += 1) {
    alteredChildren[i].hasChildren = (
      alteredChildren[i].children && alteredChildren[i].children.length > 0
    );
    flatTree.push(alteredChildren[i]);
    if (alteredChildren[i].hasChildren) {
      flatTree = flatTree.concat(makeTreeFlat(alteredChildren[i].children));
    }
  }
  return flatTree;
};

export const updatePaths = (children, path, labeledPath) => {
  const alteredChildren = children;
  if (alteredChildren.length > 0) {
    for (let i = 0; i < alteredChildren.length; i += 1) {
      alteredChildren[i].path = path.concat(alteredChildren[i].id);
      const slash = labeledPath === '' ? '' : ' / ';
      alteredChildren[i].labeledPath = `${labeledPath}${slash}${alteredChildren[i].label}`;
      alteredChildren[i].children = updatePaths(
        alteredChildren[i].children,
        path.concat(alteredChildren[i].id),
        `${labeledPath}${slash}${alteredChildren[i].label}`,
      );
    }
  }
  return alteredChildren;
};

export const spreadHiddenAll = (children, hidden) => {
  if (children.length > 0) {
    return children.map(
      child => (
        {
          ...child,
          hidden,
          children: spreadHidden(child.children, hidden),
        }
      ),
    );
  }
  return [];
};

export const toggleOpenAll = (children, opened) => {
  const alteredChildren = children;
  if (alteredChildren.length > 0) {
    for (let i = 0; i < alteredChildren.length; i += 1) {
      alteredChildren[i].opened = opened;
      if (alteredChildren[i].children.length > 0) {
        // On descend dans tous les noeuds fils
        // pour les masquer également
        alteredChildren[i].children = spreadHiddenAll(
          alteredChildren[i].children,
          !opened,
        );
        alteredChildren[i].children = toggleOpenAll(
          alteredChildren[i].children,
          opened,
        );
      }
    }
  }
  return alteredChildren;
};

export const spreadHidden = (children, hidden) => {
  if (children.length > 0) {
    return children.map(
      child => (
        {
          ...child,
          hidden,
          children: !child.opened ? child.children : spreadHidden(child.children, hidden),
        }
      ),
    );
  }
  return [];
};

export const toggleOpen = (children, node) => {
  const alteredChildren = children;
  if (alteredChildren.length > 0) {
    for (let i = 0; i < alteredChildren.length; i += 1) {
      if (node.id === alteredChildren[i].id) {
        alteredChildren[i].opened = !node.opened;
        // On descend dans tous les noeuds fils
        // pour les masquer également
        alteredChildren[i].children = spreadHidden(
          alteredChildren[i].children,
          !node.opened,
        );
      } else {
        alteredChildren[i].children = toggleOpen(
          alteredChildren[i].children,
          node,
        );
      }
    }
  }
  return alteredChildren;
};

export const toggleEditLabel = (children, node) => {
  if (children.length > 0) {
    return children.map(
      child => (
        {
          ...child,
          editLabel: (child.id === node.id ? !node.editLabel : child.editLabel),
          children: toggleEditLabel(child.children, node),
        }
      ),
    );
  }
  return [];
};

export const patchStoreNode = (children, item) => {
  if (Array.isArray(children) && children.length > 0) {
    return children.map(
      child => (
        child.id === item.id ? {
          ...item,
          opened: child.opened !== undefined ? child.opened : true,
          children: child.children,
          found: child.type === 'bin' ? 1 : child.found,
        } : {
          ...child,
          children: patchStoreNode(child.children, item),
        }
      ),
    );
  }
  return [];
};

export const deleteNode = (children, node) => {
  const alteredChildren = children;
  if (alteredChildren.length > 0) {
    for (let i = 0; i < alteredChildren.length; i += 1) {
      if (alteredChildren[i].id === node.id) {
        alteredChildren.splice(i, 1);
        break;
      } else if (alteredChildren[i].children) {
        alteredChildren[i].children = deleteNode(alteredChildren[i].children, node);
      }
    }
  }
  return alteredChildren;
};

const updateLevels = (children, parentLevel) => {
  if (Array.isArray(children) && children.length > 0) {
    const newLevel = parentLevel + 1;
    return children.map(
      child => (
        {
          ...child,
          level: newLevel,
          opened: true,
          children: updateLevels(child.children, newLevel),
        }
      ),
    );
  }
  return [];
};

export const addNodeToList = (children, node, sort, level) => {
  if (!Array.isArray(children)) {
    return [];
  }
  let sortField = sort.split(' ')[0];
  if (sortField === 'alpha') {
    sortField = 'label';
  }
  const sortOrder = sort.split(' ')[1];
  const alteredChildren = [...children];
  const alteredNode = {
    ...node,
    opened: node.opened !== undefined ? node.opened : true,
    found: 1,
    isNewInList: true,
    level,
    children: updateLevels(node.children, level),
  };
  if (alteredChildren.length === 0) {
    alteredChildren.push(alteredNode);
  } else if (!_.find(alteredChildren, { id: alteredNode.id })) {
    let inserted = false;
    for (let j = 0; j < alteredChildren.length; j += 1) {
      if (alteredChildren[j].type !== 'bin') {
        if (sortOrder === 'asc') {
          if (alteredNode[sortField] <= alteredChildren[j][sortField]) {
            alteredChildren.splice(j, 0, alteredNode);
            inserted = true;
            break;
          }
        } else if (sortOrder === 'desc') {
          if (alteredNode[sortField] >= alteredChildren[j][sortField]) {
            alteredChildren.splice(j, 0, alteredNode);
            inserted = true;
            break;
          }
        }
      }
    }
    if (!inserted) {
      alteredChildren.push(alteredNode);
    }
  }
  return alteredChildren;
};

const modifyNbKeywordsInBranch = (
  children,
  branchIds,
  nbKeywords,
  add,
  originNodeId,
  isNodeMoving,
) => {
  const alteredChildren = children;
  if (alteredChildren.length > 0) {
    for (let i = 0; i < alteredChildren.length; i += 1) {
      if (_.includes(branchIds, alteredChildren[i].id)) {
        if (add) {
          alteredChildren[i].nb_keywords += nbKeywords;
        } else {
          alteredChildren[i].nb_keywords -= nbKeywords;
        }
        // On modifie également le nb_keywords_self pour le noeud
        // "origine", sauf si cette méthode est appelée après un déplacement
        // de noeud (on ne rajoute pas les mots clés du noeud déplacé au
        // noeud parent...)
        if (!isNodeMoving && alteredChildren[i].id === originNodeId) {
          if (add) {
            alteredChildren[i].nb_keywords_self += nbKeywords;
          } else {
            alteredChildren[i].nb_keywords_self -= nbKeywords;
          }
        }
        alteredChildren[i].children = modifyNbKeywordsInBranch(
          alteredChildren[i].children,
          branchIds,
          nbKeywords,
          add,
          originNodeId,
          isNodeMoving,
        );
        break;
      } else if (alteredChildren[i].children) {
        alteredChildren[i].children = modifyNbKeywordsInBranch(
          alteredChildren[i].children, branchIds, nbKeywords, add, originNodeId, isNodeMoving,
        );
      }
    }
  }
  return alteredChildren;
};

export const modifyNbKeywords = (tree, nodeId, nbKeywords, add, isNodeMoving) => {
  const node = findNodeById(tree, nodeId);
  return modifyNbKeywordsInBranch(tree.children, node.path, nbKeywords, add, nodeId, isNodeMoving);
};

export const addNode = (children, node, sort, toRoot) => {
  if (children) {
    if (toRoot) {
      return addNodeToList(children, node, sort, 1);
    }
    return children.map(
      child => (
        child.id === node.parent_id ? {
          ...child,
          children: addNodeToList(child.children, node, sort, child.level + 1),
        } : {
          ...child,
          children: addNode(child.children, node, sort),
        }
      ),
    );
  }
  return [];
};

export const moveStoreNode = (children, item, sort, toRoot) => {
  const alteredChildren = deleteNode(children, item);

  return addNode(alteredChildren, item, sort, toRoot);
};

export const isEmptyNode = (node) => {
  let isEmpty = true;
  if (node.nb_keywords > 0 || (
    Array.isArray(node.children)
    && node.children.length > 0
  )) {
    isEmpty = false;
  }
  return isEmpty;
};

// Méthode utile au "cochage" d'une liste de mots-clés/uncats...
// entre un id d'élément et la précédente case cochée (ou décochée).
// Utile lorsqu'on a cliqué sur une checkbox avec la touche shift appuyée,
// par exemple
export const getChildrenToCheck = (children, clickedId) => {
  // On prépare la liste des cases à cocher
  const childrenToCheck = [];
  if (!_.isEmpty(children)) {
    // On cherche les bornes, d'abord l'indice de la précédente case cochée (ou décochée)
    const start = _.findIndex(children, { isLastChecked: true });
    // Ensuite l'indice de la case cliquée
    const end = _.findIndex(children, { id: clickedId });
    // Si on a bien une case précédemment cochée (ou décochée)
    if (start !== -1) {
      // On organise les bornes de l'indice le plus petit à l'indice le plus haut
      const from = Math.min(start, end);
      const to = Math.max(start, end);
      // On boucle sur le bon "range" dans la liste des éléments
      for (let i = from; i <= to; i += 1) {
        if (!children[i].checked) {
          // Si jamais la case n'est pas déjà cochée, on ajoute son id
          // dans la liste
          childrenToCheck.push(children[i].id);
        }
      }
    }
  }
  return childrenToCheck;
};

export const patchKeywordsInList = (children, items) => {
  if (!(Array.isArray(children) && children.length > 0)) {
    return [];
  }
  if (!(Array.isArray(items) && items.length > 0)) {
    return children;
  }

  const alteredChildren = [...children];

  for (let i = 0; i < items.length; i += 1) {
    for (let j = 0; j < alteredChildren.length; j += 1) {
      if (alteredChildren[j].id === items[i].id) {
        alteredChildren[j] = {
          ...items[i],
          checked: alteredChildren[j].checked,
        };
        break;
      }
    }
  }
  return alteredChildren;
};

export const deleteKeywordsFromList = (children, items) => {
  if (!(Array.isArray(children) && children.length > 0)) {
    return [];
  }
  if (!(Array.isArray(items) && items.length > 0)) {
    return children;
  }

  const alteredChildren = [...children];

  for (let i = 0; i < items.length; i += 1) {
    for (let j = alteredChildren.length - 1; j >= 0; j -= 1) {
      if (alteredChildren[j].id === items[i].id) {
        alteredChildren.splice(j, 1);
        break;
      }
    }
  }
  return alteredChildren;
};

export const addKeywordsToList = (children, items, sort, isGrouped) => {
  if (!Array.isArray(children)) {
    return [];
  }
  let sortField = sort.split(' ')[0];
  if (sortField === 'alpha') {
    sortField = 'label';
  }
  const sortOrder = sort.split(' ')[1];
  const alteredChildren = [...children];
  for (let i = 0; i < items.length; i += 1) {
    const alteredItem = {
      ...items[i],
      isNewInList: true,
      checked: false,
    };
    if (alteredChildren.length === 0) {
      alteredChildren.push(alteredItem);
    } else if (!_.find(alteredChildren, { id: alteredItem.id }) || isGrouped) {
      let inserted = false;
      if (isGrouped) {
        // Si le noeud actif est en mode groupé, on commence par virer l'item de la liste
        // existante, il sera réunséré dans la boucle suivante quoiqu'il arrive (et au bon endroit)
        for (let j = alteredChildren.length - 1; j >= 0; j -= 1) {
          if (alteredItem.id === alteredChildren[j].id) {
            alteredChildren.splice(j, 1);
          }
        }
      }
      for (let j = 0; j < alteredChildren.length; j += 1) {
        // Si le noeud actif est en mode groupé, on s'assure d'abord d'être dans
        // le bon "noeud" avant de tenter une insertion
        if (isGrouped && alteredChildren[j].parent_id !== alteredItem.parent_id) {
          continue;
        }
        // Ensuite, dans le cadre du mode groupé, on teste si le parent de l'élément suivant
        // est différent du courant, pour savoir si on a changé de groupe ou non
        let isAtTheEnd;
        let nextChildrenHasOtherParent;
        if (isGrouped) {
          isAtTheEnd = (j === alteredChildren.length - 1);
          if (!isAtTheEnd) {
            nextChildrenHasOtherParent = (
              (alteredChildren[j + 1].parent_id) !== alteredItem.parent_id
            );
          } else {
            nextChildrenHasOtherParent = false;
          }
        }
        const comparisonOptions = {
          sensitivity: 'base',
        };
        if (sortField === 'count') {
          comparisonOptions.numeric = true;
        }
        const comparison = String(alteredItem[sortField]).localeCompare(
          String(alteredChildren[j][sortField]),
          undefined,
          comparisonOptions,
        );
        if (sortOrder === 'asc') {
          if (comparison <= 0
            || (isGrouped && nextChildrenHasOtherParent)
          ) {
            let insertTo = j;
            // Si on est rentré ici du fait de nextChildrenHasOtherParent et
            // non de la comparaison des éléments, alors on insère à la place
            // du suivant, afin de prende en compte le changement de groupe
            if (!(comparison <= 0)) {
              insertTo = j + 1;
            }
            alteredChildren.splice(insertTo, 0, alteredItem);
            inserted = true;
            break;
          }
        } else if (sortOrder === 'desc') {
          if (comparison >= 0
            || (isGrouped && nextChildrenHasOtherParent)
          ) {
            let insertTo = j;
            // Si on est rentré ici du fait de nextChildrenHasOtherParent et
            // non de la comparaison des éléments, alors on insère à la place
            // du suivant, afin de prende en compte le changement de groupe
            if (!(comparison >= 0)) {
              insertTo = j + 1;
            }
            alteredChildren.splice(insertTo, 0, alteredItem);
            inserted = true;
            break;
          }
        }
      }
      if (!inserted) {
        alteredChildren.push(alteredItem);
      }
    }
  }
  return alteredChildren;
};

export const stripTags = (text, allowed) => {
  let alteredText = text;
  const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
  const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
  alteredText = alteredText.replace(commentsAndPhpTags, '').replace(tags, ($0, $1) => (
    allowed.indexOf(`<${$1.toLowerCase()}>`) > -1 ? $0 : ''
  ));
  alteredText = alteredText.replace(/<em>/g, '<b>');
  alteredText = alteredText.replace(/<\/em>/g, '</b>');
  return alteredText;
};
