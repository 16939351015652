import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import {
  Clear,
  List,
  People,
  PowerSettingsNew,
  Search,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import s from './Header.module.sass';

import logoOMSmall from './logoOMSmall.svg';
import AdminMenuContainer from '../../containers/AdminMenuContainer';
import { ontologyPropType } from '../../core/proptypes';

const Header = ({
  logged,
  location,
  handleSearch,
  resetSearch,
  globalSearch,
  handleSourceFilter,
  handleSearchChange,
  handleOntologyChange,
  ontologies,
  activeOntology,
  sources,
  userIsSuperAdmin,
  activeSource,
}) => {

  const {
    pathname,
  } = location;
  const isUsersPage = pathname === '/users';

  return (
    logged && (
    <div className={s.root}>
      <AppBar position="static">
        <Toolbar className={s.menuItemContainer}>
            <div className={s.displayFlexCenter}>
              <img
                src={logoOMSmall}
                className={s.logo}
                alt="Qwam Logo"
              />
              {!isUsersPage && ontologies && activeOntology && (
                ontologies.length > 1 ? (
                  <Select
                    value={activeOntology.nature}
                    classes={{
                      root: s.headerSelect,
                      icon: s.whiteIcon,
                    }}
                    variant="outlined"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={handleOntologyChange}
                  >
                    {ontologies.map(
                      ontology => (
                        <MenuItem
                          button
                          key={ontology.nature}
                          component={Link}
                          to={`/ontology-manager/${ontology.nature}/tree/${ontology.id}`}
                          value={ontology.nature}
                        >
                          {ontology.label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                ) : (
                  activeOntology.label
                )
              )}
              {!isUsersPage && ontologies && activeOntology && (
                <AdminMenuContainer
                  activeOntology={activeOntology}
                />
              )}
            </div>
            <div className={s.rightContainer}>
              {!isUsersPage && (
                <Fragment>
                  {!_.isEmpty(sources) && (
                    <FormControl className={s.headerSelect}>
                      <InputLabel>
                        {activeSource.length > 0 ? "" : "Source(s)"}
                      </InputLabel>
                      <Select
                        multiple
                        value={activeSource}
                        classes={{
                          root: s.headerSelect,
                          icon: s.whiteIcon,
                          input: s.marginTop0,
                        }}
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: '400px'
                            },
                          },
                        }}
                        onChange={handleSourceFilter}
                      >
                        {sources.map(
                          source => (
                            <MenuItem
                              button
                              key={source.id}
                              value={source.id}
                            >
                              {source.label}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  )}
                  {ontologies && activeOntology && (
                    <div className={s.search}>
                      <div className={s.searchIcon}>
                        <Search />
                      </div>
                      <InputBase
                        value={globalSearch}
                        placeholder="Rechercher"
                        classes={{
                          root: s.inputRoot,
                          input: s.inputInput,
                        }}
                        onChange={handleSearchChange}
                        onKeyDown={handleSearch}
                        endAdornment={(
                          <InputAdornment position="end">
                            <Tooltip
                              title="Annuler la recherche globale"
                            >
                              <IconButton
                                className={s.resetSearchIcon}
                                onClick={resetSearch}
                              >
                                <Clear />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )}
                      />
                    </div>
                  )}
                </Fragment>
              )}
              {userIsSuperAdmin && (
                isUsersPage ? (
                  <Tooltip title="Ontologies">
                    <MenuItem
                      component={Link}
                      to="/ontology-manager"
                    >
                      <List style={{ marginRight: "5px" }}/>
                      Ontologies
                    </MenuItem>
                  </Tooltip>
                ) : (
                  <Tooltip title="Gestion des utilisateurs">
                    <MenuItem
                      component={Link}
                      to="/users"
                    >
                      <People style={{ marginRight: "5px" }} />
                      Utilisateurs
                    </MenuItem>
                  </Tooltip>
                )
              )}
              <Tooltip title="Se déconnecter">
                <MenuItem
                  component={Link}
                  to="/logout"
                >
                  <PowerSettingsNew />
                </MenuItem>
              </Tooltip>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  ));
};

Header.propTypes = {
  logged: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleOntologyChange: PropTypes.func.isRequired,
  globalSearch: PropTypes.string,
  ontologies: PropTypes.arrayOf(
    ontologyPropType,
  ),
  activeOntology: ontologyPropType,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  activeSource: PropTypes.arrayOf(
    PropTypes.number,
  ),
};

Header.defaultProps = {
  activeSource: [],
  globalSearch: '',
  ontologies: [],
  sources: [],
  activeOntology: {},
};

export default Header;
