import { post, get } from './api';

const login = values => post({ path: '/authenticate', bodyItems: values, withToken: false })
  .then(xhrResponse => xhrResponse.json())
  .then(response => response);

const logout = () => get({ path: '/logout' });

const clearAuth = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
};

const storeAuth = ({ user, token }) => {
  if (user && token) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  }
};

export {
  login,
  logout,
  clearAuth,
  storeAuth,
};
