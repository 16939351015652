import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import AdminMenu from '../components/AdminMenu/AdminMenu';
import { ontologyPropType } from '../core/proptypes';
import { defaultOntology } from '../config/constants';

class AdminMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSetDialogExportOntology = this.handleSetDialogExportOntology.bind(this);
    this.handleSetDialogPurgeSource = this.handleSetDialogPurgeSource.bind(this);
    this.handleSetDialogImport = this.handleSetDialogImport.bind(this);
    this.handleSetDialogWord2VecImport = this.handleSetDialogWord2VecImport.bind(this);
    this.handleSetDialogEditOntology = this.handleSetDialogEditOntology.bind(this);
    this.handleSetDeletingOntology = this.handleSetDeletingOntology.bind(this);
    this.handleSetDialogNewOntology = this.handleSetDialogNewOntology.bind(this);
  }

  handleSetDialogExportOntology() {
    const {
      activeOntology,
      setDialogExportOntology,
    } = this.props;

    if (activeOntology) {
      setDialogExportOntology(activeOntology);
    }
  }

  handleSetDialogPurgeSource() {
    const {
      activeOntology,
      setDialogPurgeSource,
    } = this.props;

    if (activeOntology) {
      setDialogPurgeSource(activeOntology);
    }
  }

  handleSetDialogWord2VecImport() {
    const {
      activeOntology,
      setDialogWord2VecImport,
    } = this.props;

    if (activeOntology) {
      setDialogWord2VecImport(activeOntology);
    }
  }

  handleSetDialogImport(importType) {
    const {
      activeOntology,
      setDialogImport,
    } = this.props;

    if (activeOntology) {
      setDialogImport(activeOntology.nature, importType);
    }
  }

  handleSetDialogEditOntology() {
    const {
      activeOntology,
      setDialogEditOntology,
    } = this.props;

    if (activeOntology) {
      setDialogEditOntology(activeOntology);
    }
  }

  handleSetDeletingOntology() {
    const {
      activeOntology,
      setDeletingOntology,
    } = this.props;

    if (activeOntology) {
      setDeletingOntology(activeOntology);
    }
  }

  handleSetDialogNewOntology() {
    const {
      setDialogEditOntology,
    } = this.props;

    setDialogEditOntology();
  }

  render() {
    const {
      activeOntology,
      canDeleteOntology,
      userIsSuperAdmin,
    } = this.props;

    return (
      <AdminMenu
        activeOntology={activeOntology}
        canDeleteOntology={canDeleteOntology}
        userIsSuperAdmin={userIsSuperAdmin}
        handleSetDialogEditOntology={this.handleSetDialogEditOntology}
        handleSetDeletingOntology={this.handleSetDeletingOntology}
        handleSetDialogNewOntology={this.handleSetDialogNewOntology}
        handleSetDialogExportOntology={this.handleSetDialogExportOntology}
        handleSetDialogPurgeSource={this.handleSetDialogPurgeSource}
        handleSetDialogImport={this.handleSetDialogImport}
        handleSetDialogWord2VecImport={this.handleSetDialogWord2VecImport}
      />
    );
  }
}

AdminMenuContainer.propTypes = {
  userIsSuperAdmin: PropTypes.bool.isRequired,
  activeOntology: ontologyPropType.isRequired,
  canDeleteOntology: PropTypes.bool.isRequired,
  setDialogExportOntology: PropTypes.func.isRequired,
  setDialogPurgeSource: PropTypes.func.isRequired,
  setDialogWord2VecImport: PropTypes.func.isRequired,
  setDialogImport: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const nbKeywordsInActiveOntology = _.get(state, 'ontologyManager.tree.content.nb_keywords', null);
  const hasNoKeywords = nbKeywordsInActiveOntology === 0;
  const childrenInActiveOntology = _.get(state, 'ontologyManager.tree.content.children', []);
  let hasNoNodes = _.isEmpty(childrenInActiveOntology);
  if (!hasNoNodes
    && childrenInActiveOntology.length === 1
    && childrenInActiveOntology[0].type == "bin"
  ) {
    hasNoNodes = true;
  }

  return {
    userIsSuperAdmin: _.get(state, 'auth.user.admin', false),
    canDeleteOntology: hasNoKeywords && hasNoNodes,
  };
};

const mapDispatchToProps = dispatch => ({
  setDialogEditOntology: (ontology) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: ontology || defaultOntology,
        actionType: 'EDIT_ONTOLOGY',
      },
    });
  },
  setDialogExportOntology: (ontology) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: ontology,
        actionType: 'EXPORT',
      },
    });
  },
  setDialogPurgeSource: (ontology) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: ontology,
        actionType: 'PURGE',
      },
    });
  },
  setDialogWord2VecImport: (ontology) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: ontology,
        actionType: 'WORD2VEC',
      },
    });
  },
  setDialogImport: (nature, importType) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: {
          nature,
          importType,
          ontology: 1000,
        },
        actionType: 'IMPORT',
      },
    });
  },
  setDeletingOntology: (ontology) => {
    dispatch({
      type: 'SET_DIALOG_ITEM',
      dialog: {
        item: ontology,
        actionType: 'DELETE_ONTOLOGY',
      },
    });
  },
});

const enhancer = _.flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhancer(AdminMenuContainer);
