import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Fab,
  IconButton,
  Tooltip,
} from '@material-ui/core';

const tagsMap = {
  button: Button,
  iconButton: IconButton,
  fab: Fab,
};

const ChosenButton = React.forwardRef((props, ref) => {
  const { tag } = props;
  const TagName = tagsMap[tag];
  return <TagName ref={ref} {...props} />;
});

ChosenButton.propTypes = {
  tag: PropTypes.oneOf(Object.keys(tagsMap)).isRequired,
};

const TooltipButton = ({
  title,
  children,
  tag,
  ...rest
}) => (
  <Tooltip
    title={title}
  >
    <ChosenButton
      tag={tag}
      component="div"
      {...rest}
    >
      {children}
    </ChosenButton>
  </Tooltip>
);

TooltipButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  tag: PropTypes.string,
};
TooltipButton.defaultProps = {
  tag: 'button',
  title: '',
};

export default TooltipButton;
