import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  Check,
} from '@material-ui/icons';
import cx from 'classnames';
import TooltipButton from '../TooltipButton';
import s from './InteractiveButton.module.sass';

const InteractiveButton = ({
  children,
  loading,
  validated,
  title,
  ...rest
}) => (
  <div className={s.root}>
    <div className={s.positionRelative}>
      {title
        ? (
          <TooltipButton
            title={title}
            {...rest}
          >
            {children}
          </TooltipButton>
        ) : (
          <Button
            {...rest}
          >
            {children}
          </Button>
        )
      }
      {
        loading && (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={24}
            className={cx(s.centeredItem, s.btnProgress)}
          />
        )
      }
      {
        validated && (
          <Check
            className={cx(s.centeredItem, s.checkIcon)}
          />
        )
      }
    </div>
  </div>
);

InteractiveButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  validated: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};
InteractiveButton.defaultProps = {
  disabled: false,
  loading: false,
  title: '',
  validated: false,
};


export default InteractiveButton;
