export const types = {
  CLEANUP_USERS: '@USERS/CLEANUP_USERS',
  FETCH_USERS: '@USERS/FETCH_USERS',
  FETCH_USERS_SUCCESS: '@USERS/FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR: '@USERS/FETCH_USERS_ERROR',
  FETCH_USER_RIGHTS: '@USERS/FETCH_USER_RIGHTS',
  FETCH_USER_RIGHTS_SUCCESS: '@USERS/FETCH_USER_RIGHTS_SUCCESS',
  FETCH_USER_RIGHTS_ERROR: '@USERS/FETCH_USER_RIGHTS_ERROR',
  CLEANUP_USER_RIGHTS: '@USERS/CLEANUP_USER_RIGHTS',
  OPEN_CLOSE_USERS: '@USERS/OPEN_CLOSE_USERS',
  OPEN_CLOSE_USERS_SUCCESS: '@USERS/OPEN_CLOSE_USERS_SUCCESS',
  OPEN_CLOSE_USERS_ERROR: '@USERS/OPEN_CLOSE_USERS_ERROR',
  OPEN_CLOSE_USERS: '@USERS/OPEN_CLOSE_USERS',
  OPEN_CLOSE_USERS_SUCCESS: '@USERS/OPEN_CLOSE_USERS_SUCCESS',
  OPEN_CLOSE_USERS_ERROR: '@USERS/OPEN_CLOSE_USERS_ERROR',
  SAVE_USER: '@USERS/SAVE_USER',
  SAVE_USER_SUCCESS: '@USERS/SAVE_USER_SUCCESS',
  SAVE_USER_ERROR: '@USERS/SAVE_USER_ERROR',
  SAVE_USER_RIGHTS: '@USERS/SAVE_USER_RIGHTS',
  SAVE_USER_RIGHTS_SUCCESS: '@USERS/SAVE_USER_RIGHTS_SUCCESS',
  SAVE_USER_RIGHTS_ERROR: '@USERS/SAVE_USER_RIGHTS_ERROR',
};

export const cleanupUsers = () => ({
  type: types.CLEANUP_USERS,
});

export const fetchUsers = (params) => ({
  type: types.FETCH_USERS,
  params,
});

export const fetchUsersSuccess = (users) => ({
  type: types.FETCH_USERS_SUCCESS,
  users,
});

export const fetchUsersError = (response) => ({
  type: types.FETCH_USERS_ERROR,
  response,
});

export const cleanupUserRights = () => ({
  type: types.CLEANUP_USER_RIGHTS,
});

export const fetchUserRights = (params) => ({
  type: types.FETCH_USER_RIGHTS,
  params,
});

export const fetchUserRightsSuccess = (rights) => ({
  type: types.FETCH_USER_RIGHTS_SUCCESS,
  rights,
});

export const fetchUserRightsError = (response) => ({
  type: types.FETCH_USER_RIGHTS_ERROR,
  response,
});

export const openCloseUsers = (ids, open, status) => ({
  type: types.OPEN_CLOSE_USERS,
  ids,
  open,
  status,
});

export const openCloseUsersSuccess = (results) => ({
  type: types.OPEN_CLOSE_USERS_SUCCESS,
  results,
});

export const openCloseUsersError = (response) => ({
  type: types.OPEN_CLOSE_USERS_ERROR,
  response,
});

export const saveUser = (params) => ({
  type: types.SAVE_USER,
  params,
});

export const saveUserSuccess = () => ({
  type: types.SAVE_USER_SUCCESS,
});

export const saveUserError = (response) => ({
  type: types.SAVE_USER_ERROR,
  response,
});

export const saveUserRights = (rights) => ({
  type: types.SAVE_USER_RIGHTS,
  rights,
});
