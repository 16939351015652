
import { useSnackbar } from 'notistack';
import React from 'react';

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
);

/**
 * Liste des options dans la doc notistack
 * https://notistack.com/api-reference#mutual-props
 */
export const snackActions = {
  success(msg, options) {
    this.toast(msg, { variant: 'success', ...options });
  },
  warning(msg, options) {
    this.toast(msg, { variant: 'warning', ...options });
  },
  info(msg, options) {
    this.toast(msg, { variant: 'info', ...options });
  },
  error(msg, options) {
    this.toast(msg, { variant: 'error', ...options });
  },
  toast(msg, options = { variant: 'default' }) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
