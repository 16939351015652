import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { saveUser } from '../services/users/actions';
import UserForm from '../components/UserForm';

const UserFormContainer = () => {
  const dialogItems = useSelector((state) => state.ontologyManager.dialog);
  const {
    societe: loggedUserCompany,
    utilisateur: loggedUserId,
  } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: 'UNSET_DIALOG_ITEM' });
    dispatch({ type: 'UNSET_DIALOG_LOADING' });
  }

  const handleSubmit = (values, form) => {
    let validValues = values;
    if (values.utilisateur == null) {
      // Pour la création d'un utilisateur, on rajoute l'état et la
      // société de l'utilisateur connecté
      values.etat_utilisateur = 1;
      values.societe = loggedUserCompany;
    } else {
      // Pour éviter d'envoyer tous les champs dans le PATCH,
      // on ne va envoyer que les champs ayant changé par rapport
      // aux valeurs initiales (+ l'ID, les valeurs forcées et l'
      // etat_utilisateur qui va servir au refresh de la liste).
      const validFields = _.concat(
        _.keys(form.getState().dirtyFields),
        ['utilisateur', 'etat_utilisateur'],
      );
      validValues = _.pickBy(values, (value, key) => (
        _.includes(validFields, key)
      ));
    }
    dispatch(saveUser(validValues));
  }

  if (_.isEmpty(dialogItems) || dialogItems.actionType !== 'EDIT_USER') {
    return '';
  }

  return (
    <UserForm
      user={dialogItems.item}
      loggedUserIsEditedUser={dialogItems.item?.utilisateur === loggedUserId}
      handleClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
}

export default UserFormContainer;
