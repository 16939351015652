import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../Layout/Layout';

const ProtectedRoutes = ({
  component: Component,
  path,
  location,
  auth,
  rollbar,
  onlyAdmin,
}) => {
  if (!auth.logged) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referer: location },
        }}
      />
    );
  }

  const { user } = auth;
  if ((onlyAdmin && !_.get(user, 'admin', false))) {
    return (
      <Redirect
        to={{
          pathname: '/ontology-manager',
          state: { referer: location },
        }}
      />
    );
  }
  if (user) {
    const { logon, email, utilisateur } = user;
    rollbar.configure({
      payload: {
        person: {
          id: utilisateur,
          username: logon,
          email,
        },
      },
    });
  }

  return (
    <Layout
      exact
      path={path}
      component={Component}
    />
  );
};

ProtectedRoutes.propTypes = {
  rollbar: PropTypes.shape({
    configure: PropTypes.func.isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    logged: PropTypes.bool.isRequired,
    user: PropTypes.shape(),
  }).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onlyAdmin: PropTypes.bool,
};

ProtectedRoutes.defaultProps = {
  onlyAdmin: false,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(ProtectedRoutes);
