import React from 'react';
import Draggable from 'react-draggable';
import { Paper } from '@material-ui/core';

const DraggablePaper = React.memo(props => (
  <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
));

export default DraggablePaper;
