import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
} from 'react-final-form';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Select,
  TextField,
} from 'final-form-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { SlideUp } from './Transitions/Transitions';
import DraggablePaper from './DraggablePaper/DraggablePaper';
import { utilisateurPropType } from '../core/proptypes';
import DialogConfirmButtonContainer from '../containers/DialogConfirmButtonContainer';
import { CIVILITY } from '../config/constants';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      maxWidth: '1200px',
    },
  },
  title: {
    alignItems: 'center',
    backgroundColor: 'lighten($primary-color, 50%)',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
  },
  dialogContent: {
    paddingBottom: '30px',
    '& > div': {
      marginBottom: '20px',
    },
  },
}));

const UserForm = ({
  user,
  handleClose,
  onSubmit,
  loggedUserIsEditedUser,
}) => {
  const classes = useStyles();

  let modalTitle;
  if (user.utilisateur) {
    modalTitle = `Edition de l'utilisateur : ${user.prenom} ${user.nom}`;
  } else {
    modalTitle = 'Création d\'un nouvel utilisateur';
  }

  const textfieldProps = {
    fullWidth: true,
    component: TextField,
    autoComplete: 'off',
    // La ligne suivante permet de forcer la valeur pour les champs texte vides
    // (sinon react-final-form les vire lors du onSubmit...)
    parse: value => (value),
  };
  const validateRequired = (value) => !value?.trim() ? 'Champ obligatoire' : null;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={user}
      render={({
        handleSubmit,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Dialog
            className={classes.root}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose();
                }
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                handleSubmit();
              }
            }}
            open
            maxWidth="md"
            fullWidth
            TransitionComponent={SlideUp}
            PaperComponent={DraggablePaper}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle
              style={{ cursor: 'move' }}
              id="draggable-dialog-title"
              disableTypography
              className={classes.title}
            >
              <Typography variant="h6">{modalTitle}</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              dividers
              className={classes.dialogContent}
            >
              {user.utilisateur && (
                <Field
                  name="utilisateur"
                  type="hidden"
                  component={TextField}
                />
              )}
              <Box display="flex" sx={{ gap: '20px' }} flexWrap="wrap" alignItems="center">
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="civilite"
                    label="Civilité"
                    component={Select}
                    formControlProps={{ fullWidth: true }}
                    parse={(value) => (isNaN(parseFloat(value)) ? "" : parseFloat(value))}
                    MenuProps={{
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                      transformOrigin: { vertical: 'top', horizontal: 'left' },
                      getContentAnchorEl: null
                    }}
                  >
                    {
                      _.map(CIVILITY,
                        (gender, key) => (
                          <MenuItem
                            key={key}
                            value={key}
                          >
                            {gender}
                          </MenuItem>
                        ),
                      )
                    }
                  </Field>
                </Box>
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="nom"
                    label="Nom"
                    required
                    validate={validateRequired}
                  />
                </Box>
                <Box flexGrow="1">
                  <Field
                    {...textfieldProps}
                    name="prenom"
                    label="Prénom"
                    required
                    validate={validateRequired}
                  />
                </Box>
              </Box>
              <Field
                {...textfieldProps}
                name="logon"
                label="Identifiant"
                InputProps={{
                  inputProps: {
                    autoComplete: 'new-password',
                  },
                }}
                required
                validate={validateRequired}
              />
              {(user.utilisateur == null || loggedUserIsEditedUser) && (
                <Fragment>
                  <Field
                    {...textfieldProps}
                    name="password"
                    label="Mot de passe"
                    InputProps={{
                      type: 'password',
                      inputProps: {
                        maxLength: '50',
                        autoComplete: 'new-password',
                      },
                    }}
                    required
                    validate={validateRequired}
                  />
                  <Field
                    {...textfieldProps}
                    name="passwordConfirm"
                    label="Confirmer votre mot de passe"
                    InputProps={{
                      type: 'password',
                      inputProps: {
                        maxLength: '50',
                        autoComplete: 'new-password',
                      }
                    }}
                    required
                    validate={(value) => value !== values.password ? 'Les mots de passe ne sont pas identiques' : null}
                  />
                </Fragment>
              )}
              <Field
                {...textfieldProps}
                name="email"
                label="Email"
                required
                validate={validateRequired}
              />
            </DialogContent>

            <DialogActions>
              <DialogConfirmButtonContainer
                type="submit"
                color="primary"
                onClick={handleSubmit}
              >
                Enregistrer
              </DialogConfirmButtonContainer>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
}

UserForm.propTypes = {
  user: utilisateurPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  loggedUserIsEditedUser: PropTypes.bool.isRequired,
};

export default UserForm;
