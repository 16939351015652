import * as types from './actionTypes';
import initialState from '../../core/initialState';

const authenticationReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case types.RESTORE_STATE_FROM_STORAGE:
      return {
        ...state,
        logged: false,
        loading: true,
      };

    case types.RESTORE_STATE_FROM_STORAGE_ERROR:
      return {
        ...state,
        logged: false,
        loading: false,
      };

    case types.LOGIN:
      return {
        ...state,
        logged: false,
        loading: true,
        loginError: false,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
        logged: true,
        loading: false,
        loginError: false,
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        loginError: true,
      };

    case types.LOGOUT:
      return {
        ...state,
        loading: true,
        token: undefined,
        user: undefined,
      };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        logged: false,
        loading: false,
      };

    case types.LOGOUT_ERROR:
      return {
        ...state,
        logged: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default authenticationReducer;
