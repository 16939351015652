import { get } from 'lodash';
import { BYPASS_AUTH, availableLangs } from '../config/constants';

export * from './ontology-manager-utils.js';

export const getUserFromStorage = () => {
  const item = sessionStorage.getItem('user');
  return item ? JSON.parse(item) : {};
};

export const getLogonFromStorage = () => get(getUserFromStorage(), 'logon');

export const getTokenFromStorage = () => {
  if (BYPASS_AUTH) {
    return 'qesapifirstcallin2018';
  }

  return sessionStorage.getItem('token');
};

export const cleanStorage = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
};

// Les availableLangs sont sous cette forme ['fr', 'en', ...]
// Les langues fournies par le back sont des entiers avec la
// correspondance suivante : 1 > "fr", 2 > "en"...
export const getLangByQESId = qesId => availableLangs[qesId - 1];
