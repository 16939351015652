const initialState = {
  // ontologyManager: {
  //   ontologies: { list: [] },
  //   tree: { content: {} },
  //   item: { active_node: null, uncats: [] },
  //   editingItem: { item: null },
  // },
  auth: {
    loading: false,
    logged: false,
    loginError: false,
  },
};

export default initialState;
