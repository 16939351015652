import {
  del,
  get,
  patch,
  post,
  put,
} from './api';
const handleResponse = (xhrResponse) => {
  if (!xhrResponse.ok) throw xhrResponse;
  return xhrResponse.json();
};

const fetchUsers = params => get({ path: '/utilisateur/retrieve-all',  ...params }).then(handleResponse);

const openCloseUsers = params => post({ path: '/utilisateur/open-close', ...params }).then(handleResponse);

const createUser = params => post({ path: '/utilisateur', ...params }).then(handleResponse);

const patchUser = params => patch({ path: '/utilisateur', ...params }).then(handleResponse);

const fetchUserRights = params => get({ path: '/ontology/user-rights',  ...params }).then(handleResponse);

const updateRight = params => put({ path: '/ontology/nature-rights', ...params }).then(handleResponse);

const createRight = params => post({ path: '/ontology/nature-rights', ...params }).then(handleResponse);

const deleteRight = params => del({ path: '/ontology/nature-rights', ...params }).then(handleResponse);

export {
  createUser,
  fetchUserRights,
  fetchUsers,
  openCloseUsers,
  patchUser,
  updateRight,
  createRight,
  deleteRight,
};
