import {
  put,
  call,
  takeLatest,
} from 'redux-saga/effects';
import { snackActions } from '../../utils/snackbar';
import {
  cleanupUserRights,
  fetchUserRightsError,
  fetchUserRightsSuccess,
  fetchUsers,
  fetchUsersError,
  fetchUsersSuccess,
  openCloseUsersError,
  openCloseUsersSuccess,
  saveUserError,
  saveUserSuccess,
  types,
} from './actions';
import {
  createRight,
  createUser,
  deleteRight,
  fetchUserRights,
  fetchUsers as fetchUsersApi,
  openCloseUsers as openCloseUsersApi,
  patchUser,
  updateRight,
} from '../../api/users-api';
import { fetchError } from '../auth/actions';
import { CLOSED_USER_STATUS, OPEN_USER_STATUS } from '../../config/constants';

function* workFetchUsers({ params }) {
  try {
    const users = yield call(fetchUsersApi, { uriParams: params } );
    yield put(fetchUsersSuccess(users));
  } catch (response) {
    yield put(fetchUsersError(response));
    yield put(fetchError(response));
    snackActions.error('Erreur lors de la récupération des utilisateurs');
  }
}

function* watchFetchUsers() {
  yield takeLatest(types.FETCH_USERS, workFetchUsers);
}

function* workOpenCloseUsers({ ids, open, status }) {
  try {
    const etat = (open) ? OPEN_USER_STATUS : CLOSED_USER_STATUS;
    const message = (open) ? 'utilisateur(s) activé(s)' : 'utilisateur(s) désactivé(s)';
    const params = {
      utilisateurs: ids,
      etat_utilisateur: etat,
    };

    const results = yield call(openCloseUsersApi, { bodyItems: { ...params } });
    yield put(openCloseUsersSuccess(results));
    snackActions.info(`${ids.length} ${message}`);
    yield put(fetchUsers({ etat: status }));
  } catch (error) {
    yield put(openCloseUsersError(error));
    console.error(error);
    snackActions.error('Erreur lors de la mise à jour des utilisateurs');
  }
}

function* watchOpenCloseUsers() {
  yield takeLatest(types.OPEN_CLOSE_USERS, workOpenCloseUsers);
}

function* workSaveUser({ params }) {
  try {

    yield put({ type: 'SET_DIALOG_LOADING' });
    let endpoint = createUser;
    if (params.utilisateur !== null) {
      endpoint = patchUser;
    }
    yield call(endpoint, { bodyItems: { ...params } });
    yield put(saveUserSuccess());
    yield put({ type: 'UNSET_DIALOG_ITEM' });
    // On refresh la liste des utilisateurs
    yield put(fetchUsers({ etat: params.etat_utilisateur }));
    snackActions.info('Utilisateur enregistré');
  } catch (error) {
    yield put(saveUserError(error));
    console.error(error);
    snackActions.error('Erreur lors de l\'enregistrement de l\'utilisateur');
  } finally {
    yield put({ type: 'UNSET_DIALOG_LOADING' });
  }
}

function* watchSaveUser() {
  yield takeLatest(types.SAVE_USER, workSaveUser);
}

function* workFetchUserRights({ params }) {
  try {
    const rights = yield call(fetchUserRights, { uriParams: params } );
    yield put(fetchUserRightsSuccess(rights));
  } catch (response) {
    yield put(fetchUserRightsError(response));
    yield put(fetchError(response));
    snackActions.error('Erreur lors de la récupération des droits de l\'utilisateur');
  }
}

function* watchFetchUserRights() {
  yield takeLatest(types.FETCH_USER_RIGHTS, workFetchUserRights);
}

function* workSaveUserRights({ rights }) {
  try {

    yield put({ type: 'SET_DIALOG_LOADING' });
    for (const type in rights) {
      let endpoint;
      if (type === 'create') {
        endpoint = createRight;
      } else if (type === 'update') {
        endpoint = updateRight;
      } else if (type === 'delete') {
        endpoint = deleteRight;
      }
      for (let i = 0; i < rights[type].length; i++) {
        const right = rights[type][i];
        let params;
        if (type === 'delete') {
          params = { uriParams: { user: right.utilisateur, nature: right.nature } };
        } else {
          params = { bodyItems: { ...right } };
        }
        yield call(endpoint, params);
      }
    }

    yield put({ type: 'UNSET_DIALOG_ITEM' });
    yield put(cleanupUserRights());
    snackActions.info('Droits de l\'Utilisateur enregistrés');
  } catch (error) {
    console.error(error);
    snackActions.error('Erreur lors de l\'enregistrement des droits');
  } finally {
    yield put({ type: 'UNSET_DIALOG_LOADING' });
  }
}

function* watchSaveUserRights() {
  yield takeLatest(types.SAVE_USER_RIGHTS, workSaveUserRights);
}

export default {
  watchFetchUsers,
  watchFetchUserRights,
  watchOpenCloseUsers,
  watchSaveUser,
  watchSaveUserRights,
};
