import React from 'react';
import CenteredCircularProgress from '../CenteredCircularProgress';
import s from './CenteredInPageCircularProgress.module.sass';

const CenteredInPageCircularProgress = () => (
  <div
    className={s.centeredInPage}
  >
    <CenteredCircularProgress />
  </div>
);

export default CenteredInPageCircularProgress;
