import queryString from 'query-string';

import {
  getTokenFromStorage,
  getLogonFromStorage,
} from '../utils/utils';

const genericGenerator = (basePath, method) => ({
  path,
  uriParams,
  bodyItems,
  withToken = true,
  fileUpload = false,
}) => {
  let paramsStr;
  let body;

  if (uriParams) {
    paramsStr = queryString.stringify(uriParams);
  }

  const headers = {};
  if (fileUpload) {
    body = bodyItems;
  } else {
    headers['Content-Type'] = 'application/json';
    body = bodyItems ? JSON.stringify(bodyItems) : undefined;
  }

  if (withToken) {
    headers.logon = getLogonFromStorage();
    headers.key = getTokenFromStorage();
  }

  let url = basePath ? `${basePath}${path}` : path;
  if (paramsStr) {
    url += `?${paramsStr}`;
  }

  return fetch(`${url}`, {
    method,
    headers,
    body,
    credentials: 'include',
  });
};

const delGenerator = basePath => genericGenerator(basePath, 'DELETE');
const getGenerator = basePath => genericGenerator(basePath, 'GET');
const patchGenerator = basePath => genericGenerator(basePath, 'PATCH');
const postGenerator = basePath => genericGenerator(basePath, 'POST');
const putGenerator = basePath => genericGenerator(basePath, 'PUT');

export {
  delGenerator,
  getGenerator,
  patchGenerator,
  postGenerator,
  putGenerator,
};
