import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import UserRights from '../components/UserRights';
import { snackActions } from '../utils/snackbar';
import { fetchOntologies } from '../api/ontology-manager-api';
import { cleanupUserRights, fetchUserRights, saveUserRights } from '../services/users/actions';

const UserRightsContainer = () => {
  const dialogItems = useSelector((state) => state.ontologyManager.dialog);
  const ontologies = useSelector((state) => state.ontologyManager.ontologies.list);
  const loadingOntologies = useSelector((state) => state.ontologyManager.ontologies.loading);
  const loadingRights = useSelector((state) => state.users.rightsLoading);
  const originalUserRights = useSelector((state) => state.users.rights);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogItems?.actionType === 'EDIT_USER_RIGHTS' && !dialogItems.loading) {
      // Si on a pas la liste des ontologies disponibles, on la charge
      // (si on est pas passé par la page principale)
      if (_.isEmpty(ontologies)) {
        dispatch({
          type: 'FETCH_ONTOLOGIES',
        });

        fetchOntologies()
          .then((response) => {
            if (!response.ok) dispatchFetchOntologiesError(response);
            return response.json();
          })
          .then((data) => {
            dispatch({
              type: 'FETCH_ONTOLOGIES_SUCCESS',
              ontologies: data,
            });
            // On charge les droits de l'utilisateur demandé
            dispatch(fetchUserRights({ user: dialogItems.item.utilisateur }));

          })
          .catch(dispatchFetchOntologiesError);
      } else {
        // On charge les droits de l'utilisateur demandé
        dispatch(fetchUserRights({ user: dialogItems.item.utilisateur }));
      }
    }
  }, [dialogItems]);

  const dispatchFetchOntologiesError = (response) => {
    snackActions.error('Erreur lors du chargement des ontologies', { variant: 'error' });
    dispatch({
      type: FETCH_ERROR,
      response,
    });

    dispatch({
      type: 'FETCH_ONTOLOGIES_ERROR',
    });
  };

  const handleClose = () => {
    dispatch({ type: 'UNSET_DIALOG_ITEM' });
    dispatch({ type: 'UNSET_DIALOG_LOADING' });
    dispatch(cleanupUserRights());
  }

  const handleSubmit = (rights) => {
    // On fait un differenceBy sur le champ nature pour voir les ontologies présentes dans
    // les droits originaux qui ne sont plus dans les droits finaux, ce qui nous permet
    // d'avoir la liste des droits à SUPPRIMER
    const rightsToDelete = _.differenceBy(originalUserRights, rights, 'nature');
    // On fait un differenceBy sur nature "dans le sens inverse" pour voir les ontologies
    // présentes dans les droits finaux qui n'étaient pas dans les droits originaux, ce
    // qui nous permet d'avoir la liste des droits à CREER
    const rightsToCreate = _.differenceBy(rights, originalUserRights, 'nature');
    // Enfin, on fait un differenceWith pour comparer l'égalité entre les ontologies
    // existantes dans les droits finaux (qui ont des id et donc qui existaient forcément
    // dans les droits originaux) et dans les droits originaux, ce qui nous permet
    // d'avoir la liste des droits à METTRE A JOUR
    const rightsToUpdate = _.differenceWith(
      _.filter(rights, 'id'),
      originalUserRights,
      _.isEqual
    );

    dispatch(
      saveUserRights({
        create: rightsToCreate,
        update: rightsToUpdate,
        delete: rightsToDelete,
      })
    );
  }

  if (_.isEmpty(dialogItems) || dialogItems.actionType !== 'EDIT_USER_RIGHTS') {
    return '';
  }

  return (
    <UserRights
      user={dialogItems.item}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      ontologies={ontologies}
      userRights={originalUserRights}
      loadingOntologies={loadingOntologies}
      loadingRights={loadingRights}
    />
  );
}

export default UserRightsContainer;
