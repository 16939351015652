import { fork, all } from 'redux-saga/effects';
import AuthSagas from './auth/sagas';
import UsersSagas from './users/sagas';

function* rootSaga() {
  const modules = [
    AuthSagas,
    UsersSagas,
  ];

  const sagas = modules
    .flatMap((module) => Object.values(module)
      .filter((watcher) => typeof watcher === 'function'));

  yield all(sagas.map(saga => fork(saga)));
}

export default rootSaga;
