import {
  take,
  put,
  call,
  fork,
  cancel,
  takeEvery,
} from 'redux-saga/effects';

import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  restoreStateFromStorageError,
} from './actions';

import {
  login,
  logout,
  clearAuth,
  storeAuth,
} from '../../api/auth';

import * as types from './actionTypes';

function* restoreStateFromStorage() {
  let user;
  let token;

  try {
    user = JSON.parse(sessionStorage.getItem('user'));
    token = sessionStorage.getItem('token');
  } catch (e) {
    console.error('can\'t retrieve keys from store', e);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  }

  if (user && token) {
    yield put(loginSuccess({ user, token }));
  } else {
    yield put(restoreStateFromStorageError('can\'t retreive user from storage'));
  }
}

function* authenticate({ logon, password }) {
  try {
    const { key: token, utilisateur: user } = yield call(login, { logon, password });
    yield call(storeAuth, { token, user });
    yield put(loginSuccess({ user, token }));
  } catch (error) {
    yield put(loginError(error));
  }
}

function* unauthenticate() {
  try {
    const { response } = yield call(logout);
    yield put(logoutSuccess(response));
  } catch (error) {
    yield put(logoutError(error));
  }
}

function* checkAuthValidity({ response }) {
  // this could append if the token became invalid, so we should be sure
  // the storage is clean to avoid infinit loops
  // the application context should be erased by @restoreStateFromStorage method
  const isPromiseResponse = response instanceof Response;
  if (isPromiseResponse && !response.ok && response.status === 401) {
    yield put({ type: types.LOGOUT });
  }
}

function* watchFetchErrors() {
  yield takeEvery(types.FETCH_ERROR, checkAuthValidity);
}

function* watchAuthRestauration() {
  yield takeEvery(types.RESTORE_STATE_FROM_STORAGE, restoreStateFromStorage);
}

function* watchAuth() {
  while (true) {
    const { type, logon, password } = yield take([types.LOGIN, types.LOGIN_SUCCESS]);
    let taskAuthenticate;
    if (type === types.LOGIN) {
      taskAuthenticate = yield fork(authenticate, { logon, password });
    }

    const action = yield take([types.LOGOUT, types.LOGIN_ERROR]);
    if (action.type === types.LOGOUT) {
      if (taskAuthenticate) yield cancel(taskAuthenticate);
      yield call(unauthenticate);
    }
    yield call(clearAuth);
  }
}

export default {
  watchAuthRestauration,
  watchAuth,
  watchFetchErrors,
};
