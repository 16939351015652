import React from 'react';
import {
  CircularProgress,
  Grid,
} from '@material-ui/core';
import s from './CenteredCircularProgress.module.sass';

const CenteredCircularProgress = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    className={s.height100}
  >
    <Grid
      item
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
      />
    </Grid>
  </Grid>
);

export default CenteredCircularProgress;
