import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import InteractiveButton from '../components/InteractiveButton';

const DialogConfirmButtonContainer = ({
  loading,
  children,
  ...rest
}) => (
  <InteractiveButton
    disabled={loading}
    loading={loading}
    {...rest}
  >
    {children}
  </InteractiveButton>
);

DialogConfirmButtonContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapStateToProps = state => ({
  loading: _.get(state, 'ontologyManager.dialog.loading', false),
});

export default connect(
  mapStateToProps,
  {}, // Evite de propager un "dispatch" dans les props du composant
)(DialogConfirmButtonContainer);
