import {
  del,
  get,
  patch,
  post,
} from './api';

const fetchOntologies = () => get({ path: '/ontology/nature' });

const fetchOntology = (natureId) => {
  const params = {
    nature: natureId,
  };
  return get({ path: '/ontology/nature', uriParams: params });
};

const fetchTree = (treeId, queryParams) => {
  const params = {
    sort: 'alpha asc',
    type: ['cats', 'rws', 'bin'],
    recursive: true,
    ...queryParams,
  };

  return get({ path: `/ontology/tree/${treeId}`, uriParams: params });
};

const fetchUncats = (natureId, queryParams) => {
  const params = {
    sort: 'count desc',
    nature: natureId,
    type: 'orphans',
    ...queryParams,
  };

  return get({ path: '/ontology', uriParams: params });
};

const fetchNode = (nodeId, queryParams, excludeId) => {
  const params = {
    sort: 'count desc',
    type: 'kws',
    recursive: true,
    exclude: excludeId,
    ...queryParams,
  };

  return get({ path: `/ontology/children/${nodeId}`, uriParams: params });
};

const patchItems = leafs => patch({ path: '/ontology', bodyItems: leafs });
const createItem = values => post({ path: '/ontology', bodyItems: values });
const deleteNode = nodeId => del({ path: `/ontology/${nodeId}` });

const createOntology = values => post({ path: '/ontology/nature', bodyItems: values });
const patchOntology = values => patch({ path: '/ontology/nature', bodyItems: values });
const deleteOntology = natureId => del({ path: `/ontology/nature/${natureId}` });

const deleteKeywords = keywordsIds => post({ path: `/ontology/delete-bin?id=${keywordsIds.join('&id=')}` });
const purgeSource = ({ id, nature }) => post({ path: `/ontology/purge-source?id=${id}&nature=${nature}` });
const emptyBin = (natureId) => del({ path: `/ontology/destroy-bin?nature=${natureId}` });

const fetchContexts = (natureId, fieldSearch, label) => {
  const params = {
    start: 0,
    slice: 20,
    sort: '',
    nature: natureId,
    query: `${fieldSearch}:"${label}"`,
  };
  return get({ path: '/elastic/search-result', uriParams: params });
};

const fetchKeywordSuggestions = (natureId, keywordId, queryParams) => {
  const params = {
    sort: 'path,score desc',
    nature: natureId,
    id: keywordId,
    ...queryParams,
  };
  return get({ path: '/ontology/suggest-for-id', uriParams: params });
};

const fetchNodeSuggestions = (natureId, nodeId, queryParams) => {
  const params = {
    sort: 'path,score desc',
    nature: natureId,
    id: nodeId,
    ...queryParams,
  };
  return get({ path: '/ontology/suggest-for-children', uriParams: params });
};

const fetchKeywordBigrams = (natureId, keywordId, queryParams) => {
  const params = {
    sort: 'path,score desc',
    nature: natureId,
    id: keywordId,
    ...queryParams,
  };
  return get({ path: '/ontology/bigram-for-id', uriParams: params });
};

const fetchNodeBigrams = (natureId, nodeId, queryParams) => {
  const params = {
    sort: 'path,score desc',
    nature: natureId,
    id: nodeId,
    ...queryParams,
  };
  return get({ path: '/ontology/bigram-for-children', uriParams: params });
};

const exportOntology = (natureId, treeId) => {
  const params = {
    nature: natureId,
    ontology: treeId,
  };

  return post({ path: '/ontology/export-to-path', uriParams: params });
};

const importWikidata = queryParams => post({ path: '/ontology/import-from-dbpedia', uriParams: { count: true, ...queryParams } });

const importElastic = queryParams => post({ path: '/ontology/import-from-field', uriParams: { count: true, ...queryParams } });

const importFromFileUpload = file => post({ path: '/ontology/import-from-file', fileUpload: true, bodyItems: file });
const importFromFile = queryParams => post({ path: '/ontology/import-from-file', uriParams: { count: true, ...queryParams } });
const importFromFileSuggestions = queryParams => post({ path: '/ontology/import-suggestion-from-file', uriParams: queryParams });
const importRelations = queryParams => post({ path: '/ontology/import-relations', uriParams: queryParams });
const importUniqueKeywords = queryParams => post({ path: '/ontology/import-mots-uniques', uriParams: queryParams });

const importWord2Vec = (natureId, treeId) => {
  const params = {
    nature: natureId,
    ontology: treeId,
  };

  return post({ path: '/ontology/import-from-suggestion', uriParams: params });
};

export {
  createItem,
  createOntology,
  deleteNode,
  deleteKeywords,
  emptyBin,
  exportOntology,
  fetchContexts,
  fetchKeywordSuggestions,
  fetchNode,
  fetchNodeSuggestions,
  fetchKeywordBigrams,
  fetchNodeBigrams,
  fetchOntologies,
  fetchOntology,
  fetchTree,
  fetchUncats,
  importWikidata,
  importElastic,
  importFromFileUpload,
  importFromFile,
  importFromFileSuggestions,
  importWord2Vec,
  importRelations,
  importUniqueKeywords,
  patchItems,
  patchOntology,
  deleteOntology,
  purgeSource,
};
