import PropTypes from 'prop-types';
import { CIVILITY } from '../config/constants';

const ontologyPropType = PropTypes.shape({
  driver: PropTypes.string,
  fieldDisplay: PropTypes.string,
  fieldFacets: PropTypes.string,
  fieldHighlight: PropTypes.string,
  fieldIdentifier: PropTypes.string,
  fieldSearch: PropTypes.string,
  fieldSuggestion: PropTypes.string,
  fieldTitle: PropTypes.string,
  id: PropTypes.number,
  label: PropTypes.string,
  nature: PropTypes.number,
  nbcontain: PropTypes.number,
  nbsim: PropTypes.number,
  nbsugg: PropTypes.number,
  show_admin: PropTypes.bool,
  show_create: PropTypes.bool,
  show_delete: PropTypes.bool,
  show_export: PropTypes.bool,
  show_export: PropTypes.bool,
  show_import_elastic: PropTypes.bool,
  show_import_fichier: PropTypes.bool,
  show_import_relation: PropTypes.bool,
  show_import_suggestion: PropTypes.bool,
  show_import_unique: PropTypes.bool,
  show_import_wikidata: PropTypes.bool,
  show_knn: PropTypes.bool,
  show_purge: PropTypes.bool,
  show_relation: PropTypes.bool,
  show_suggestion: PropTypes.bool,
  show_update: PropTypes.bool,
  urlEnrich: PropTypes.string,
  urlPhrase: PropTypes.string,
  urlRelation: PropTypes.string,
  urlSearch: PropTypes.string,
});

const utilisateurPropType = PropTypes.shape({
  civilite: PropTypes.oneOf(Object.keys(CIVILITY).map(Number)),
  email: PropTypes.string,
  admin: PropTypes.bool,
  logon: PropTypes.string.isRequired,
  utilisateur: PropTypes.number,
  nom: PropTypes.string,
  prenom: PropTypes.string,
});

const rightPropType = {
  id: PropTypes.number,
  utilisateur: PropTypes.number,
  nature: PropTypes.number,
  show_suggestion: PropTypes.bool,
  show_create: PropTypes.bool,
  show_update: PropTypes.bool,
  show_delete: PropTypes.bool,
  show_purge: PropTypes.bool,
  show_admin: PropTypes.bool,
  show_import_wikidata: PropTypes.bool,
  show_import_elastic: PropTypes.bool,
  show_import_fichier: PropTypes.bool,
  show_import_suggestion: PropTypes.bool,
  show_import_relation: PropTypes.bool,
  show_import_unique: PropTypes.bool,
  show_export: PropTypes.bool,
};

export {
  ontologyPropType,
  rightPropType,
  utilisateurPropType,
};
