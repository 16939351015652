import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SwipeableDrawer,
} from '@material-ui/core';
import TooltipButton from '../TooltipButton';
import s from './Sidenav.module.sass';

const Sidenav = ({
  children,
  button,
  title,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = (open) => {
    setOpen(open);
  };

  return (
    <Fragment>
      <TooltipButton
        onClick={() => toggleOpen(true)}
        title={title}
        {...rest}
      >
        {button}
      </TooltipButton>
      <SwipeableDrawer
        onClose={() => toggleOpen(false)}
        onOpen={() => toggleOpen(true)}
        open={isOpen}
        classes={{
          paper: s.importExportDrawerPaper,
        }}
        {...rest}
      >
        {children}
      </SwipeableDrawer>
    </Fragment>
  );
};

Sidenav.propTypes = {
  button: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};
Sidenav.defaultProps = {
  title: '',
};

export default Sidenav;
