import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Box,
  FormControlLabel,
  Typography,
  Button,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { BlockOutlined, CheckOutlined, Edit, PersonAdd, VerifiedUserOutlined } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import TooltipButton from '../TooltipButton/TooltipButton';
import { utilisateurPropType } from '../../core/proptypes';
import s from './Users.module.sass';
import { CLOSED_USER_REQUEST, OPENED_USER_REQUEST, defaultUser } from '../../config/constants';
import UserFormContainer from '../../containers/UserFormContainer';
import UserRightsContainer from '../../containers/UserRightsContainer';

const customToolbarSelect = (rowsSelected, data, statusFilter, handleClickOpenCloseSelected) => (
  statusFilter === CLOSED_USER_REQUEST ? (
    <Button
      color="primary"
      variant="text"
      style={{
        marginRight: '16px',
      }}
      onClick={() => handleClickOpenCloseSelected(rowsSelected, data, true)}
      startIcon={<CheckOutlined />}
    >
      Activer
    </Button>
  ) : (
    <Button
      color="primary"
      variant="text"
      style={{
        marginRight: '16px',
      }}
      onClick={() => handleClickOpenCloseSelected(rowsSelected, data, false)}
      startIcon={<BlockOutlined />}
    >
      Désactiver
    </Button>
  )
);

const customBodyRenderActions = (
  tableMeta,
  users,
  handleClickOpenClose,
  handleClickEdit,
  handleClickEditRights,
) => {
  const user = _.find(users, { utilisateur: tableMeta.rowData[0] });
  const closedState = _.split(CLOSED_USER_REQUEST, ',');
  return (
    <Box>
      {(closedState.includes(`${user.etat_utilisateur}`)) ? (
        <TooltipButton
          tag="fab"
          color="primary"
          title="Activer"
          onClick={() => handleClickOpenClose(tableMeta.rowData[0], true)}
          size="small"
          className={s.actionBtnSmall}
        >
          <CheckOutlined />
        </TooltipButton>
      ) : (
        <TooltipButton
          tag="fab"
          color="primary"
          title="Désactiver"
          onClick={() => handleClickOpenClose(tableMeta.rowData[0], false)}
          size="small"
          className={s.actionBtnSmall}
        >
          <BlockOutlined />
        </TooltipButton>
      )}
      <TooltipButton
        tag="fab"
        color="primary"
        title="Modifier"
        onClick={() => handleClickEdit(user)}
        size="small"
        className={s.actionBtnSmall}
      >
        <Edit />
      </TooltipButton>
      <TooltipButton
        tag="fab"
        color="primary"
        title="Modifier les droits"
        onClick={() => handleClickEditRights(user)}
        size="small"
        className={s.actionBtnSmall}
      >
        <VerifiedUserOutlined />
      </TooltipButton>
    </Box>
  );
};

const Users = ({
  users,
  loading,
  handleToggleStatusButton,
  statusFilter,
  setSelectedRows,
  selectedRows,
  handleClickEdit,
  handleClickEditRights,
  handleClickOpenClose,
  handleClickOpenCloseSelected,
}) => {
  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: "Utilisateur",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'logon',
      label: "Login",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: "Mail",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'actions',
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          customBodyRenderActions(tableMeta, users, handleClickOpenClose, handleClickEdit, handleClickEditRights)
        ),
      },
    },
  ];

  const data = users.map(
    (user) => [
      user.utilisateur,
      `${user.prenom} ${user.nom}`,
      user.logon,
      user.email,
    ],
  );

  const textLabels = {
    body: {
      noMatch: 'Désolé, aucun utilisateur ne correspond',
      toolTip: 'Trier',
    },
    pagination: {
      next: 'Page suivante',
      previous: 'Page précédente',
      rowsPerPage: 'Lignes par page :',
      displayRows: 'de',
      jumpToPage: 'Aller à la page',
    },
    toolbar: {
      search: 'Rechercher',
      downloadCsv: 'Télécharger le CSV',
      print: 'Imprimer',
      viewColumns: 'Voir les colonnes',
      filterTable: 'Filtrer la table',
    },
    filter: {
      all: 'Tous',
      title: 'FILTRES',
      reset: 'RESET',
    },
    viewColumns: {
      title: 'Voir les colonnes',
      titleAria: 'Montrer/Cacher les colonnes',
    },
    selectedRows: {
      text: 'utilisateur(s) sélectionnée(s)',
    },
  };

  const options = {
    filter: false,
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    filterType: 'checkbox',
    fixedHeader: true,
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: loading ? 'Chargement de la liste des utilisateurs' : textLabels.body.noMatch,
      },
    },
    downloadOptions: { filename: 'users_list.csv' },
    onChangePage: () => {
      const table = document.querySelector('#datatable');
      table.scrollIntoView(true);
    },
    sortOrder: {
      name: 'date_of_creation',
      direction: 'desc',
    },
    responsive: 'simple',
    setTableProps: () => ({ size: 'small' }),
    rowsSelected: selectedRows,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setSelectedRows(rowsSelected);
    },
    customToolbarSelect: (rowsSelected) => (
      customToolbarSelect(rowsSelected, data, statusFilter, handleClickOpenCloseSelected)
    ),
  };
  return (
    <Box
      className={s.root}
      width="100%"
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Box display="flex" flexShrink="0" alignItems="center">
            <Typography variant="h6" style={{ lineHeight: '38px', marginRight: '16px' }}>Utilisateurs</Typography>
            <FormControlLabel
              control={(
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={
                    (e, value) => {
                      if (value) {
                        handleToggleStatusButton(value)
                      }
                    }
                  }
                  value={statusFilter}
                  size="small"
                >
                  <ToggleButton value={OPENED_USER_REQUEST}>
                    ACTIVÉS
                  </ToggleButton>
                  <ToggleButton value={CLOSED_USER_REQUEST}>
                    DÉSACTIVÉS
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
            {statusFilter === OPENED_USER_REQUEST && (
              <Button
                color="primary"
                variant="text"
                style={{
                  marginRight: '16px',
                }}
                onClick={() => handleClickEdit(defaultUser)}
                startIcon={<PersonAdd />}
              >
                Nouveau
              </Button>
            )}
          </Box>
        )}
        data={data}
        columns={columns}
        options={options}
      />
      <UserFormContainer />
      <UserRightsContainer />
    </Box>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(utilisateurPropType),
  loading: PropTypes.bool,
  handleToggleStatusButton: PropTypes.func.isRequired,
  statusFilter: PropTypes.string,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  handleClickEdit: PropTypes.func.isRequired,
  handleClickEditRights: PropTypes.func.isRequired,
  handleClickOpenClose: PropTypes.func.isRequired,
  handleClickOpenCloseSelected: PropTypes.func.isRequired,
};

Users.defaultProps = {
  users: [],
  selectedRows: [],
  loading: false,
  statusFilter: OPENED_USER_REQUEST,
};

export default Users;
