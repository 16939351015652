import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import HeaderContainer from '../../containers/HeaderContainer';

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="flex-start"
        spacing={0}
      >
        <HeaderContainer />

        <Grid container>
          <Component {...matchProps} />
        </Grid>
      </Grid>
    )}
  />
);

Layout.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default Layout;
