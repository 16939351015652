import {
  delGenerator,
  getGenerator,
  postGenerator,
  patchGenerator,
  putGenerator,
} from './apiGenerator';

import { API_ENTRY_POINT } from '../config/constants';

const del = delGenerator(API_ENTRY_POINT);
const get = getGenerator(API_ENTRY_POINT);
const post = postGenerator(API_ENTRY_POINT);
const patch = patchGenerator(API_ENTRY_POINT);
const put = putGenerator(API_ENTRY_POINT);

export {
  del,
  get,
  post,
  patch,
  put,
};
