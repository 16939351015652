import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import _ from 'lodash';

import Header from '../components/Header';
import { ontologyPropType } from '../core/proptypes';

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    const {
      history,
    } = props;
    const {
      search
    } = history.location;
    const searchObject = queryString.parse(search);
    this.state = {
      globalSearch: '',
      activeSource: [],
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSourceFilter = this.handleSourceFilter.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleOntologyChange = this.handleOntologyChange.bind(this);
  }


  handleSearch(event) {
    if (event.key === 'Enter') {
      const str = event.target.value;

      const {
        match,
        history,
      } = this.props;

      const {
        search
      } = history.location;

      const {
        natureId,
        treeId,
      } = match.params;

      var searchObject = queryString.parse(search);
      const strValues = queryString.stringify({
        ...searchObject,
        qt: str,
        qn: str,
        qs: str,
        qu: str,
        groupLeafs: true,
      })

      history.push({
        pathname: `/ontology-manager/${natureId}/tree/${treeId}/node/${treeId}`,
        search: `?${strValues}`,
      });
    }
  }

  handleSourceFilter(event) {
    const source = event.target.value;

    const {
      match,
      history,
    } = this.props;

    const {
      search
    } = history.location;

    const {
      natureId,
      treeId,
    } = match.params;

    this.setState({
      activeSource: source,
    });

    var searchObject = queryString.parse(search);
    const strValues = queryString.stringify({
      ...searchObject,
      srcu: source,
      srcn: source,
    })

    history.push({
      pathname: `/ontology-manager/${natureId}/tree/${treeId}/node/${treeId}`,
      search: `?${strValues}`,
    });
  }

  resetSearch() {
    const {
      history,
    } = this.props;

    const {
      search
    } = history.location;

    this.setState({
      globalSearch: '',
    });

    const searchKeys = ['qt', 'qn', 'qs', 'qu', 'groupLeafs'];

    var searchObject = queryString.parse(search);
    const strValues = queryString.stringify(
      _.omitBy({...searchObject}, (value, key) => (
        _.includes(searchKeys, key)
      ))
    );

    history.push({
      pathname: location.pathname,
      search: `?${strValues}`,
    });
  }

  handleOntologyChange() {
    const { resetItems } = this.props;
    this.setState({
      globalSearch: '',
      activeSource: [],
    });
    resetItems();
  }

  handleSearchChange(event) {
    this.setState({
      globalSearch: event.target.value,
    });
  }

  render() {
    const {
      ontologies,
      activeOntology,
      sources,
      userIsSuperAdmin,
    } = this.props;
    const { globalSearch, activeSource } = this.state;
    return (
      <Header
        {...this.props}
        handleSearch={this.handleSearch}
        handleSourceFilter={this.handleSourceFilter}
        resetSearch={this.resetSearch}
        globalSearch={globalSearch}
        activeSource={activeSource}
        handleSearchChange={this.handleSearchChange}
        handleOntologyChange={this.handleOntologyChange}
        ontologies={ontologies}
        activeOntology={activeOntology}
        sources={sources}
        userIsSuperAdmin={userIsSuperAdmin}
      />
    );
  }
}

HeaderContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  logged: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      natureId: PropTypes.string,
      treeId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape(),
  location: PropTypes.shape(),
  ontologies: PropTypes.arrayOf(
    ontologyPropType,
  ),
  activeOntology: ontologyPropType,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  resetItems: PropTypes.func.isRequired,
  userIsSuperAdmin: PropTypes.bool.isRequired,
};

HeaderContainer.defaultProps = {
  match: {},
  history: {},
  location: {},
  sources: [],
  ontologies: [],
  activeOntology: {},
};

const mapStateToProps = state => ({
  loading: state.auth.loading,
  logged: state.auth.logged,
  ontologies: _.get(state, 'ontologyManager.ontologies.list', null),
  activeOntology: _.get(state, 'ontologyManager.ontologies.active_ontology', null),
  sources: _.get(state, 'ontologyManager.ontologies.active_ontology.sources', null),
  userIsSuperAdmin: _.get(state, 'auth.user.admin', false),
});

const mapDispatchToProps = dispatch => ({
  resetItems: () => {
    dispatch({
      type: 'RESET_ITEMS',
    });
  },
});

const enhancer = _.flowRight(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhancer(HeaderContainer);
